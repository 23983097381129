import React from 'react';
import "./Servic.css";
import { BsHexagon } from 'react-icons/bs';

const Servic = ({icon, link, heading, text}) => {
  return <div className='feature'>
  <div className='feature-icon'>
  <BsHexagon color='#C73866' size={55}/>
  <div className='inner-icon'>{icon}</div>
  </div>
  <div className='feature-text'> 
  <a href={link}><h3>{heading}</h3></a>
  <p className='u-text-small'>
 {text}
   </p>
  </div>
      </div>;
};

export default Servic;
