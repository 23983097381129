import React from 'react';
import "./Blogs.css";
import Navbar from '../components/navbar/Navbar';
import Footer from '../components/footer/Footer';


import Nutform from "../imagess/ntform.jpg";
import Hwework from "../imagess/fitpllan.jpg";
import Advntimg from "../imagess/fitchall.jpg";
import FaceAdd from "../imagess/faceadd.jpg";

import Bookone from "../imagess/bookone.jpg";
import Booktwo from "../imagess/booktwo.jpg";
import Bookthree from "../imagess/bookthree.jpg";


import Chess from "../imagess/chess.png";
import Sudoku from "../imagess/sudoku.png";
import Crossword from "../imagess/crosswords.png";



const Strongmind = () => {
    return (
        <>
            <header className='header-bbg'>
                <Navbar />
            </header>
            <section className='body-left'>
                <div class="bg-gray-200 min-h-screen">
                    <div className="max-w-screen-xl mx-auto px-4 py-16">
                        <div className="flex flex-col flex-wrap md:flex-row md:-mx-3">
                            <div className="w-full lg:w-3/4 mb-8 lg:mb-0">
                                <div className="p-8 bg-white">
                                    <h1 className=" font-bold text-4xl md:text-5xl pb-8 leading-tight">MENTAL FITNESS</h1>
                                    <div className="bg-gray-200 mb-6 inline-block py-1 px-2 italic text-sm">Posted on: <time datetime="2022-02-24 13:30">Feb 27, 2022</time> by <b><u>fitbeautie Blogger Team</u></b></div>
                                    <div className="leading-relaxed">
                                        <h2 className="font-bold text-3xl pb-6 leading-tight">The strong mind can achieve practically anything and succeed at it.</h2>
                                        <p className="pb-6">When we talk about having a strong mindset, we're referring to your attitude, viewpoints, and approach to life, as well as yourself and the rest of the world. We're discussing someone who is conscious of his or her surroundings and situation.</p>
                                        <p>Look, we've all seen a lot of motivational videos about having a strong mind. It works, but only for a short period of time. Every person who tries to make videos or blogs on this topic is attempting to persuade you that their words are true and that you should share them with others so that they can gain fame, money, and so on. It doesn't matter what they want, the point is that after watching them, will you be able to create that freedom of being free from all of the chains that society has taught you that you won't be able to survive without.</p>
                                         
                          
                                        <h2 className="font-bold text-3xl pb-6 leading-tight">Feeling Free Inside Oneself Is being Free</h2>
                                        <p className="pb-6">In reality, what matters is one's mental state; what one does with one's body is only a performance! As a result, rather than blaming individuals for their physical actions, the strong mind should teach them how to refrain from such actions on a psychological level. In truth, Krishna has married and fought battles; he also had a passion for fine clothing and was a slave to his taste buds; and when the situation called for it, he used abilities such as lying, deception effectively. But it's important to remember that Krishna merely performed these acts physically; at the psychological level he would always remain detached from these deeds.</p>
                                        <p className="pb-6">So, if i summarize my knowledge, a strong mind is a person who is free of societal validations. A powerful mind is like  "The flame of a lamp in a windless place".</p>       
                                        <h2 className=" font-bold text-3xl pb-6 leading-tight">When we examine the attributes of a flame, we discover that it is light that dispels darkness.</h2>
                                        <p className=" pb-6">Joyful and happy are qualities of a powerful individual. Despite their difficulties, they treat life as a party. Even in the midst of a crisis, they are one of the best managers and the best planners. Because they are calm and sorted from within, they can be a fantastic guru (teacher) or a better companion because they can easily spell out your doubts, uncertainty, and emotional pain. Simplicity, they take everything in life very easily. They see everything as a gift and take pleasure in every sip. They are master communicators who know exactly what to say, when to say it, and how to say it to everyone. The powerful mind can achieve practically anything and succeed at it.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               
        <section className='third-container'>
        <h2 className="font-bold text-3xl pb-6 leading-tight">How are you going to accomplish it? Right</h2>
        <div className='container services'>
          <div className='u-title'>
            <h2>1. The first step is to meditate</h2>
            <p className='u-text-small u-text-dark'>Videos that can assist you in learning how to meditate.</p>
             </div>
        </div>
        <div className="row row-cols-1 row-cols-md-3 g-4">
          <div className="col">
            <div className="card h-100">
              <div className="ratio ratio-16x9">
                <iframe src="https://www.youtube.com/embed/hbfyVu0arXE" title="YouTube video" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100">
              <div className="ratio ratio-16x9">
                <iframe src="https://www.youtube.com/embed/rakSfom_v9c" title="YouTube video" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100 ">
              <div className="ratio ratio-16x9">
                <iframe src="https://www.youtube.com/embed/ZjE3kRiNkJ8" title="YouTube video" allowfullscreen></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='fourth-container'>
      <div className='container services'>
          <div className='u-title'>
            <h2>2. Knowledge</h2>
            <p className='u-text-small u-text-dark'>Knowledge leads to consciousness, and awareness leads to power. There are millions of books available online and offline. I prefer to read books on paper. The following are a few books that I would recommend.</p>
          </div>
        </div>
        <div className="row row-cols-1 row-cols-md-3 g-4">
          <div className="col">
            <div className="card h-100">
              <img src={Bookone} className="card-img-top" alt="..." />
                </div>
          </div>
          <div className="col">
            <div className="card h-100">
              <img src={Booktwo} className="card-img-top" alt="..." />
            </div>
          </div>
          <div className="col">
            <div className="card h-100">
              <img src={Bookthree} className="card-img-top" alt="..." />
              </div>
             </div>
        </div>
      </section>

      <section>
      <div className='container services'>
          <div className='u-title'>
            <h2>3. food/nutrition you consume</h2>
            <p className='u-text-small u-text-dark'>Food that is said to be good to the brain, particularly in terms of enhancing intellectual capacities. You may avoid a lot of mood fluctuations, energy loss, and brain tissue damage by eating the right amount and quality of food.</p>
             </div>
        </div>
        <div className='mindfood'>
            <li>Tulsi</li>
            <li>Ashwagandha</li>
            <li>Amla</li>
            <li>Oranges</li>
            <li>Leafy Vegetables</li>
            <li>Walnuts</li>
            <li>Eggs</li>
            <li>Milks</li>
            <li>Water</li>
        </div>
      </section>

      <section className='fourth-container'>
      <div className='container services'>
          <div className='u-title'>
            <h2>4. Brain Games</h2>
            <p className='u-text-small u-text-dark'>Brain games are a form of physical activity. Sudoku, chess, and crossword puzzles are few examples of games that can be played.</p>
          </div>
        </div>
        <div className="row row-cols-1 row-cols-md-3 g-4">
          <div className="col">
            <div className="card h-100">
              <img src={Chess} className="card-img-top" alt="..." />
                </div>
          </div>
          <div className="col">
            <div className="card h-100">
              <img src={Sudoku} className="card-img-top" alt="..." />
            </div>
          </div>
          <div className="col">
            <div className="card h-100">
              <img src={Crossword} className="card-img-top" alt="..." />
              </div>
             </div>
        </div>
      </section>
      </section>
                        <h2 className="font-bold text-3xl pb-6 leading-tight">Our experts will help you attain whatever fitness goal you have in mind. We will create a programme that suits your requirements. To build a strong and fit you, we blend sports science and natural movement . It's time to get started. With us, you'll discover your best self on a daily basis.</h2>
            <Footer />
        </>
    );
};

export default Strongmind;
