import React from 'react';
import "./Exercise.css";
import Button from '../../components/UI/button/Button';
import "../../components/UI/button/Button.css";


const Exercise = () => {
  return (
    <section id ="exservice">
    <div className="container heroservice mt-5 pt-5 h-25">
   <h1 className="display-1 text-white text-shadow animate-text-right-3s">
   <ul>Package Name: Holistic Wellness Package for Two</ul>
   </h1>
   <h3>This three-month package is made to suit the specific requirements and objectives of two people, such as a mother-daughter, friend, or couple, by offering customized yoga sessions online. Our skilled yoga instructors will work closely with you to design a personalized practice which includes diet and meditation too to support mental, emotional, and physical well-being.</h3>
   <div className='header-cta'>
         <Button text={"Know More"} btnClass={"btn-orange"} href="/hpwtwo"/>
         <Button text={"Buy@Rs15,000 Only"} btnClass={"btn-orange"} href="https://rzp.io/i/xgoxDBCW"/>
       </div>
 </div>
 </section>
  );
}

export default Exercise