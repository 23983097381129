import React, { useState } from "react";
import "../Signup/Signup.css"
import Navbar from '../../components/navbar/Navbar';
import Footer from '../../components/footer/Footer';
import NutriImg from "../../imagess/sevdays.png";

const Signup = () => {
    const [userData, setUserData] = useState({
        name: "",
        age: "",
        gender: "",
        phone: "",
        email: "",
        message: "Sign UP Discount ",
    });

    let name, value;
    const postUserData = (event) => {
        name = event.target.name;
        value = event.target.value;

        setUserData({ ...userData, [name]: value });
    };

    const submitData = async (event) => {
        event.preventDefault();
        const { name, age, gender, phone, email ,message } = userData;

        if (name && age && gender && phone && email && message) {

            const res = await fetch('https://fbhcontactus-default-rtdb.firebaseio.com/userDataRecords.json', {
                method: "POST",
                header: {
                    "Content-Type": "application/json",
                },

                body: JSON.stringify({
                    name, age, gender,phone, email, message

                }),

            }
            );
            if (res) {
                setUserData({
                    name: "",
                    age: "",
                    gender: "",
                    phone: "",
                    email: "",
                    message: "",
                });
                alert("Thank you for signing up. Your discount code will be sent to the registered phone number and email. Data submitted.")
            }
            else {
                alert("please fill the data properly");
            }
        } else {
            alert("please fill the data properly");
        }
    };


  return (
      <>
    <section className="contactus-section" id="fitregistration">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-10 mx-auto">
                            <div className="row">
                                <div className="contact-leftside col-12 col-lg-5">
                                    <h1 className="main-heading fw-bold">
                                   Fitbeautie Membership Discount
                                    <figure>
                                        <img
                                            src={NutriImg}
                                            alt="contactimg"
                                            className="img-fluid"
                                        />
                                    </figure>
                                    </h1>
                                    <p className="main-hero-para">
                                    By becoming a member, you can receive a 30%, 20%, or 15% extra discount.
                                    </p>
                                    
                                </div>

                                <div className="contact-rightside col-12 col-lg-7 ">
                                    <form method="POST">
                                        <div className="row">
                                            <div className="col-12 contact-input-feild">
                                                <input
                                                    type="text"
                                                    name="name"
                                                    id=""
                                                    className="form-control"
                                                    placeholder="name"
                                                    value={userData.name}
                                                    onChange={postUserData}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 contact-input-feild">
                                                <input
                                                    type="text"
                                                    name="age"
                                                    id=""
                                                    className="form-control"
                                                    placeholder="age"
                                                    value={userData.age}
                                                    onChange={postUserData}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 contact-input-feild">
                                                <input
                                                    type="text"
                                                    name="gender"
                                                    id=""
                                                    className="form-control"
                                                    placeholder="gender"
                                                    value={userData.gender}
                                                    onChange={postUserData}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 contact-input-feild">
                                                <input
                                                    type="text"
                                                    name="phone"
                                                    id=""
                                                    className="form-control"
                                                    placeholder="mobile"
                                                    value={userData.phone}
                                                    onChange={postUserData}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 contact-input-feild">
                                                <input
                                                    type="text"
                                                    name="email"
                                                    id=""
                                                    className="form-control"
                                                    placeholder="email"
                                                    value={userData.email}
                                                    onChange={postUserData}
                                                />
                                            </div>
                                        </div>
                                        <button type="submit" className="btn btn-style w-100" onClick={submitData}>
                                            submit
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
      </>
  )
}

export default Signup