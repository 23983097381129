import React from 'react';
import "./Meter.css";



const Meter = () => {
  return (
    <section id ="meter">
      <div className='container meter'>
      <h3>Some fun facts , We managed to decrease the weight of mother earth</h3>
        <h2>by 121 kilograms now ...With your efforts</h2>
        <h3>Make it happen</h3>
    </div>
    </section>
  );
};

export default Meter;
