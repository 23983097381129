import React from 'react';
import './App.css';
import Header from './components/header/Header';
import Navbar from './components/navbar/Navbar';
import Services from './components/services/Services';
import Contacts from './components/contacts/Contacts';
import Footer from './components/footer/Footer';
import Meter from './Pages/Weightmeter/Meter';
import Nutriadver from './Pages/Nutriadvertis/Nutriadver';
import Dietplan from './Pages/Dietplan/Dietplan';
import Befit from './Homme/Befit';

function Home() {
  return (
    <main>
      <header className='header-bbg'>
        <Navbar />
        <Header />
      </header>
      <Contacts/>
      <Dietplan/>  
      <Services />      
      <Nutriadver/>      
    <Meter />
      <Footer />
    </main>
  );
}

export default Home;
