import React from 'react'
import "./Dietblog.css";
import Navbar from '../../components/navbar/Navbar';
import Footer from '../../components/footer/Footer';

import Young from "../../imagess/young.jpg";
import Happyp from "../../imagess/happyp.jpg";
import Stime from "../../imagess/stime.jpg";

import Rcount from "../../imagess/rcount.jpg";
import Mphone from "../../imagess/mphone.jpg";
import Aclock from "../../imagess/aclock.jpg";

import Button from '../../components/UI/button/Button';


const Dietblog = () => {
  return (
    <>
    <header className='header-bbg'>
        <Navbar />
    </header>
    <section className="body-left" id='top'>
                <h2>Hello and Welcome to 24BEFIT, Personalized Diet Plan.</h2>
                <p className='u-text-small u-text-dark'>Our personalized diet planning service is designed to provide you with a tailored diet plan that meets your specific health and wellness goals. Our team of expert nutritionists will work closely with you to understand your dietary needs and preferences, and create a personalized diet plan that suits your lifestyle.</p>
           <p><h3>Service Inclusions:</h3>

<li>1. Initial Consultation: A 30-minute consultation with our expert nutritionist to understand your dietary needs and preferences.</li>
<li>2. Dietary Assessment: A comprehensive dietary assessment to identify your nutritional strengths and weaknesses.</li>
<li>3. Personalized Diet Plan: A tailored diet plan that meets your specific health and wellness goals, including meal planning, portion control, and snack suggestions.</li>
<li>4. Macro-Nutrient Breakdown: A detailed breakdown of your daily macro-nutrient requirements, including protein, carbohydrates, and fats.</li>
<li>5. Progress Tracking: Regular progress tracking and monitoring to ensure you're on track to meet your health and wellness goals.</li>
<li>6. Ongoing Support: Ongoing support and guidance from our expert nutritionist to help you stay motivated and accountable.</li>
</p>
<p><h3>Service Deliverables:</h3>

<li>1. A comprehensive diet plan document outlining your daily meal plan, portion control, and snack suggestions.</li>
<li>2. A detailed macro-nutrient breakdown document outlining your daily protein, carbohydrate, and fat requirements.</li>
<li>3. Regular progress tracking and monitoring reports to ensure you're on track to meet your health and wellness goals.</li>
<li>4. Ongoing support and guidance from our expert nutritionist via phone, email, or video conferencing.</li></p>

           </section>
            <div className="row row-cols-1 row-cols-md-3 g-4">
                <div className="col">
                    <div className="card h-100">
                        <img src={Young} className="card-img-top" alt="..." />
                        <div className="card-body">
                            <h5 className="card-title"><b><u>Know Your Ingredients</u></b></h5>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="card h-100">
                        <img src={Happyp} className="card-img-top" alt="..." />
                        <div className="card-body">
                            <h5 className="card-title"><b><u>You can send the diet plan via email or WhatsApp with a subject line or message that says:

"Your Personalized Diet Plan is Ready!"</u></b></h5>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="card h-100">
                        <img src={Stime} className="card-img-top" alt="..." />
                        <div className="card-body">
                            <h5 className="card-title"><b><u>Diet plans that are preplanned allow you to spend your time more wisely on other important parts of your life.</u></b></h5>
                        </div>
                    </div>
                </div>
            </div>
            <p><h3>Service Timeline:</h3>

<li>1. Initial consultation: 1-2 days</li>
<li>2. Dietary assessment: 2-3 days</li>
<li>3. Personalized diet plan creation: 3-5 days</li>
<li>4. Progress tracking and monitoring: Ongoing for 3 months</li>
<li>5. Ongoing support and guidance: Ongoing for 3 months</li></p>
<p><h3>Cancellation Policy:</h3>

<li>1. 50% refund if cancelled within 2 days of payment</li>
<li>2. 25% refund if cancelled within 5 days of payment</li>
<li>3. No refund if cancelled after 5 days of payment</li></p>
            <div className='header-cta'>
                <Button text={"Buy@Rs5000 Only"} btnClass={"btn-orange"} href="https://rzp.io/i/REiJvnL" />
                <Button text={"Home"} btnClass={"btn-orange"} href="https://fitbeautie.com/" />
                <Button text={"Top pg"} btnClass={"btn-orange"} href="#top" />
            </div>
        <br />
    <Footer />
</>
  )
}

export default Dietblog