import React from 'react'
import Price from '../BestPrice/Price'
import Footer from '../components/footer/Footer'
import Navbar from '../components/navbar/Navbar'
import { BsFillBookmarkStarFill } from 'react-icons/bs';

const Active = () => {
  return (
    <>
    <header className='header-bbg'>
    <Navbar/>
</header>
<br/>
<div className='u-title'>
      <BsFillBookmarkStarFill color='#C73866' size={30}/>
      <h2>Services</h2>
      <p className='u-text-small u-text-dark'>Your Beauty And Fitness Journey With Us</p>
      <Price/>
    </div>

<Footer/>
    </>
  )
}

export default Active