import React, {useState} from "react";
import ContImg from "../../imagess/contactus.png";
import "./Contacts.css";


const Contacts = () => {
  const [userData, setUserData] = useState({
    name: "",
    phone: "",
    email: "",
    address: "",
    message: "",
  });

  let name, value;
  const postUserData = (event) => {
    name = event.target.name;
    value = event.target.value;

    setUserData({ ...userData, [name]: value });
  };

  const submitData = async (event) => {
    event.preventDefault();
    const { name, phone, email, address, message } = userData;

    if (name && phone && email && address && message) {

      const res = await fetch('https://fbhcontactus-default-rtdb.firebaseio.com/userDataRecords.json', {
        method: "POST",
        header: {
          "Content-Type": "application/json",
        },

        body: JSON.stringify({
          name, phone, email, address, message

        }),

      }
      );
      if (res) {
        setUserData({
          name: "",
          phone: "",
          email: "",
          address: "",
          message: "",

        });
        alert("Data Submited")
      }
      else {
        alert("please fill the data properly");
      }
    } else {
      alert("please fill the data properly");
    }
  };

  return (
    <>
      <section className="contactus-section" id="contactus">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-10 mx-auto">
              <div className="row">
                <div className="contact-leftside col-12 col-lg-5">
                  <h1 className="main-heading fw-bold">
                    Connect with Our Team
                  </h1>
                  <p className="main-hero-para">
                    Our Customer Support team is ready to help!
                  </p>
                  <figure>
                    <img
                      src={ContImg}
                      alt="contactimg"
                      className="img-fluid"
                    />
                  </figure>
                </div>

                <div className="contact-rightside col-12 col-lg-7 ">
                  <form method="POST">
                    <div className="row">
                      <div className="col-12 contact-input-feild">
                        <input
                          type="text"
                          name="name"
                          id=""
                          className="form-control"
                          placeholder="name"
                          value={userData.name}
                          onChange={postUserData}
                        />
                      </div>
                    </div>
                      <div className="row">
                      <div className="col-12 contact-input-feild">
                        <input
                          type="text"
                          name="phone"
                          id=""
                          className="form-control"
                          placeholder="mobile"
                          value={userData.phone}
                          onChange={postUserData}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 contact-input-feild">
                        <input
                          type="text"
                          name="email"
                          id=""
                          className="form-control"
                          placeholder="email"
                          value={userData.email}
                          onChange={postUserData}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 contact-input-feild">
                        <input
                          type="text"
                          name="address"
                          id=""
                          className="form-control"
                          placeholder="city"
                          value={userData.address}
                          onChange={postUserData}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 ">
                        <input
                          type="text"
                          name="message"
                          id=""
                          className="form-control"
                          placeholder="enter your message"
                          value={userData.message}
                          onChange={postUserData}
                        />
                      </div>
                    </div>
                    <button type="submit" className="btn btn-style w-100" onClick={submitData}>
                      submit
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contacts;
