import React from 'react';
import './Pservice';
import Sleep from '../Sleep/Sleep';
import Exercise from '../Exercise/Exercise';
import Footer from '../../components/footer/Footer';
import Navbar from '../../components/navbar/Navbar';

const Pservice = () => {
  return <div>
        <header className='header-bbg'>
      <Navbar />
    </header>
    <Sleep/>
    <Exercise/>
      <Footer/>
  </div>;
};

export default Pservice;

