import React from 'react';
import "./Aboutus.css"
import Navbar from '../../components/navbar/Navbar';
import Footer from '../../components/footer/Footer';
import Abimg from "../../imagess/aboutus.jpg";
import Button from '../../components/UI/button/Button';

const Aboutus = () => {
    return (
        <>
            <header className='header-bbg'>
                <Navbar />
            </header>
            
            <section class="about-section">
        <div class="container">
            <div class="row">                
                <div class="content-column col-lg-6 col-md-12 col-sm-12 order-2">
                    <div class="inner-column">
                        <div class="sec-title">
                            <span class="title">24BeFit</span>
                            <h2>About Us</h2>
                        </div>
                        <div class="text">We are a holistic wellness platform dedicated to connecting you with a diverse network of experts in yoga, fitness, nutrition, lifestyle, beauty care, meditation, and healthy food recipes.</div>
                      <div class="text">
                      Our mission is to empower you to achieve optimal physical, mental, and emotional well-being, allowing you to thrive in all aspects of life. We believe that everyone deserves to live a balanced, healthy, and fulfilling life, and we're committed to helping you get there.
                      </div>
                        <div class="btn-box">
                            <a href="Contactus/" class="theme-btn btn-style-one">Contact Us</a>
                        </div>
                    </div>
                </div>

                
                <div class="image-column col-lg-6 col-md-12 col-sm-12">
                    <div class="inner-column wow fadeInLeft">
                      <div class="author-desc">
                        <h2>Your Online Fitness Services & Consultants</h2>
                        
                      </div>
                        <figure class="image-1"><a href="#" class="lightbox-image" data-fancybox="images"><img title="24befit" src={Abimg} alt=""></img></a></figure>
                     
                    </div>
                </div>
              
            </div>
           <div class="sec-title">
                            <span class="title">Our Vision</span>
                            <h2>Supportive and inclusive environment </h2>
                        </div>
          <div class="text">
          We envision a onine platform where individuals can easily access the resources and expertise they need to prioritize their health and wellness. We strive to create a supportive and inclusive environment that fosters growth, self-awareness, and empowerment.
              </div>
              <div>
              <h2>Our Expert Network</h2>

Our platform brings together a diverse group of experts who share our passion for holistic wellness. Our network includes:

<li>- Experienced yoga instructors</li>
<li>- Certified nutritionists and dietitians</li>
<li>- Lifestyle coaches and wellness experts</li>
<li>- Beauty care specialists</li>
<li>- Meditation and mindfulness teachers</li>
<li>- Healthy food recipe creators</li>

<h2>Join Our Online Services</h2>

By joining, you'll gain access to a wealth of resources, expertise, and support. We are dedicated to help you:

<li>- Achieve your fitness goals</li>
<li>- Nourish your body with healthy food and nutrition</li>
<li>- Cultivate mental clarity and inner peace through meditation and mindfulness</li>
<li>- Develop a positive body image and self-care routine</li>
<li>- Connect with like-minded individuals who share your passion for wellness</li>

<h2>Become the Best Version of Yourself

At 24BEFIT.</h2>We believe that everyone has the potential to become an alpha male or female – confident, strong, and empowered. Join today and start your journey towards optimal wellness and self-actualization.
              </div>
        </div>
    </section>
                <div className='header-cta'>
                    <Button text={"Home"} btnClass={"btn-orange"} href="https://fitbeautie.com/" />
                    <Button text={"Top pg"} btnClass={"btn-orange"} href="#top" />
                </div>
            <br />
            <Footer />
        </>
    )
}

export default Aboutus ;