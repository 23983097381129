import React from 'react';
import "./Services.css";
import Servimg from '../../imagess/wgoy.png';
import { BsFillBookmarkStarFill } from 'react-icons/bs';
import Servic from '../services/Servic';
import { featureList } from './data';
const Services = () => {
  return (
  <section id="services">
  <div className='container services'>
    <div className='u-title'>
      <BsFillBookmarkStarFill color='#C73866' size={30}/>
      <h2>Daily Blogs</h2>
      <p className='u-text-small u-text-dark'>Workout, Food and Sleep</p>
    </div>
    <div className='service-content'>
      <div className='service-left'>
        <img src={Servimg} alt='head' />
      </div>
      <div className='service-right'>
      {
        featureList.map((feature) =>
        <Servic key={feature.id} icon={feature.icon} link={feature.link}
        heading={feature.heading} text={feature.text}

        />
        )
      }
      </div>
    </div>
  </div>
  </section>
  );
};

export default Services;
