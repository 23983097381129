import React from 'react';
import "./Face.css";
import Navbar from '../../components/navbar/Navbar';
import Footer from '../../components/footer/Footer';

import Whoami from "../../imagess/right.jpg";
import Mobadict from "../../imagess/phoneaddict.jpg";
import Lifestyle from "../../imagess/lifestyle.jpg";

const Face = () => {
  return <>
    <header className='header-bbg'>
      <Navbar />
    </header>
    <div className='body-left'>
    <div className='u-title'>
      <h2>Face Problems-Food-Exercises-Yoga</h2>
      <p className='u-text-small u-text-dark'>You'll be the focus of attraction at times in your life. People are going to look at you. Make it worthwhile for you to take the time to read it. Acne, Aging, Tanning, Dullness, Dandruff, and Dry Hair are some of the most common problems that people face. With a variety of ideas and hacks, let's make it healthy and  glow .</p>
    </div>
  
    <h1>Face Problems</h1>
    
    <ol>
      <h2>	<li>Acne</li></h2>

      <p>Everyone, including your favourite celebrities, is affected by this. What is your skin type? That is the first and most important thing to figure out. Most people with pimples have oily skin, which is more prone to acne than dry skin. When it comes to pimples, dry skin isn't exactly a friendly.</p>
      <p>A pimple is similar to a coronavirus that affects your skin. The more you touch, peel, or pinch it, the more it spreads and creates a mark after blood oozes out.</p>

      <h2>	<li>Ageing</li>
      </h2>
      <p>Is it possible to reverse aging ? The answer is no. You can make things go slower, but you can't make it stop. However, there are a number of steps that can be taken to slow down the ageing process. Exercising, eating a nutritious diet, drinking plenty of water, and taking care of your skin are just a few examples.</p>
      <p>Simple foods combined with good habits can work wonders. Antioxidants, omega fatty acids, amino acids, vitamins, and proteins are found in meals that help to slow down the ageing process. Let's look at some of the readily available foods that can help -   green tea, carrot juice, walnuts, and pomegranate juice are all good for you.</p>
      <h2>	<li>Dullness</li>
      </h2>

      <p>A person's entire confidence is harmed by dull skin. It has significant psychological consequences for people. Skin that is dull might be caused by excessive sun exposure or a lack of other essential nutrients. The majority of dermatologists agree that dryness is a major cause of dull skin, and one of the most common causes of dry skin is not exfoliating enough or effectively. Dry skin that has broken down on your face must be removed in order for new, glowing cells to appear.</p>
      <p>Helpful suggestions for dealing with dull skin.Use sunscreen to protect yourself from the sun's harmful UV rays, especially during the summer. Avoid using skin care products that are greasy or include chemicals. Instead, improve the skin's appearance with natural, light, water-based products.
Adopt a healthy skin care routine at least twice a week to provide the skin with the nutrients it need. Wash your face frequently and eat a well-balanced, vitamin- and fiber-rich diet.</p>

      <h2>	<li>Tanning</li>
      </h2>
      <p>UV radiation from the sun stimulate the development of melanin in the body, resulting in sun-tanned skin.Don't worry, continue to apply a high-SPF sunscreen - at least around 30 - and reapply every three or four hours if exposed to the sun for an extended period of time. Apply sunscreen half an hour before going out in the sun. Retinol cream is used every night before going to bed.</p>
      <p>For your face, We recommend a mixture of 2 heaping teaspoons gram flour (besan) and a few drops of raw milk. On a daily basis, rub it as if it were soap. Wash it off after that. Completely abandon the use of soap. Use curd instead of milk if milk isn't available. Your skin will eventually regain its previous tone.</p>

    </ol>
    <h1>Face Exercises & Yoga</h1>
    <p>A Northwestern University study found that 20 weeks of daily facial workout resulted in noticeably tighter skin and larger upper and lower cheeks. Facial Exercises can give you a more youthful appearance.</p>
          
    <section className='third-container'>
      <div className='container services'>
            </div>
        <div className="row row-cols-1 row-cols-md-3 g-4">
          <div className="col">
            <div className="card h-100">
              <div className="ratio ratio-16x9">
                <iframe src="https://www.youtube.com/embed/QbxC-2Syo30" title="YouTube video" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100">
              <div className="ratio ratio-16x9">
                <iframe src="https://www.youtube.com/embed/t0mT6dkAHo8" title="YouTube video" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100 ">
              <div className="ratio ratio-16x9">
                <iframe src="https://www.youtube.com/embed/6A0ZYrZB70c" title="YouTube video" allowfullscreen></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
    <h1>Face Food</h1>
    <p>The significance of eating is self-evident and unquestionable. It gives us the nourishment and energy we need to grow and shine.Videos to assist you in making your skin bloom.</p>
          
    <section className='third-container'>
      <div className='container services'>
            </div>
        <div className="row row-cols-1 row-cols-md-3 g-4">
          <div className="col">
            <div className="card h-100">
              <div className="ratio ratio-16x9">
                <iframe src="https://www.youtube.com/embed/0-0veO2StcE" title="YouTube video" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100">
              <div className="ratio ratio-16x9">
                <iframe src="https://www.youtube.com/embed/bot_P0EctlM" title="YouTube video" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100 ">
              <div className="ratio ratio-16x9">
                <iframe src="https://www.youtube.com/embed/KRo0XRkIfys" title="YouTube video" allowfullscreen></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
    
      <section className='fourth-container'>
      <div className='container services'>
          <div className='u-title'>
            <h2>Better Up Blogs</h2>
            <p className='u-text-small u-text-dark'> Lifehack - Help, Tips, and Guidance to improve all aspects of your mental fitness.</p>
          </div>
        </div>
        <div className="row row-cols-1 row-cols-md-3 g-4">
          <div className="col">
            <div className="card h-100">
              <img src={Whoami} className="card-img-top" alt="..." />
              <div className="card-body">
                <h5 className="card-title">Choose the right</h5>
                <p className="card-text">Choose someone who is making your life emotionally fulfilling.Look for someone who is proud of the way you are, still encourages you to do better.Look for someone who is willing to accept your imperfections.Don’t look for perfections, look for someone who finds you...</p>
                <a href="/theright"  className="btn btn-primary">Know more</a>
              </div>
              <div className="card-footer">
                <small className="text-muted">Posted on: Feb 28, 2022</small>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100">
              <img src={Mobadict} className="card-img-top" alt="..." />
              <div className="card-body">
                <h5 className="card-title">Break the addiction.A smartphone is an addictive device which traps a soul into a lifeless planet full of lives.</h5>
                <p className="card-text">Wherever there is a wireless connection, you will inevitably find someone using a mobile device.Beware of your screen consumption time - it's a matter of life and death - of psychological life and psychological death. Raise yourself in a way that you do not lose sense of community in the fake crowd of hashtags and emojis.</p>
                <a href="/phoneaddict" className="btn btn-primary">Know more</a>
              </div>
              <div className="card-footer">
                <small className="text-muted">Posted on: Feb 26, 2022</small>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100">
              <img src={Lifestyle} className="card-img-top" alt="..." />
              <div className="card-body">
                <h5 className="card-title">Lifestyle and food habits</h5>
                <p className="card-text">Keep more fruits, low-fat dairy products (low-fat milk and low-fat yogurt). Focus on adding healthy food to your diet, rather than just taking unhealthy foods away.Know the life rules and hacks to benefit maximum.</p>
                <a href="/lifestyle"  className="btn btn-primary">Know more</a>
              </div>
              <div className="card-footer">
                <small className="text-muted">Posted on: March 04, 2022</small>
              </div>
            </div>
          </div>
        </div>
      </section>
      </div>
    <Footer />
  </>;
};

export default Face;
