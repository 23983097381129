import React from 'react';
import Contacts from '../../components/contacts/Contacts';
import Footer from '../../components/footer/Footer';
import Navbar from '../../components/navbar/Navbar';

const Contactus = () => {
  return <div>
        <header className='header-bbg'>
      <Navbar />
    </header>
    <br/><br/>
      <Contacts/>
      <Footer/>
  </div>;
};

export default Contactus;
