import React from 'react';
import "./Sleep.css";
import Button from '../../components/UI/button/Button';
import "../../components/UI/button/Button.css";


const Sleep = () => {
  return (
    <section id ="sleepservice">
    <div className="container heroservice mt-5 pt-5 h-25">
   <h1 className="display-1 text-white text-shadow animate-text-right-3s">
   <ul>Package Name: Holistic Wellness Package For One</ul>
   </h1>
   <h2>"NEW YEAR, NEW YOU!</h2>
  <p>With this three-month program, you can receive individualized yoga sessions online that is catered to your particular requirements and objectives. Together, you and our experienced yoga instructors will design a personalized practice that enhances your mental, emotional, and physical health. 
  </p>

<h3>Get ready to transform your body and mind this new year!</h3>

   <div className='header-cta'>
         <Button text={"Know More"} btnClass={"btn-orange"} href="/hpwone"/>
         <Button text={"Buy@Rs11,000 Only"} btnClass={"btn-orange"} href="https://rzp.io/i/TazcUbLi"/>
       </div>
 </div>
 </section>
  );
}

export default Sleep;