import React from 'react';
import "./Body.css";
import Navbar from '../../components/navbar/Navbar';
import Footer from '../../components/footer/Footer';

import Warmup from "../../imagess/warmup.jpg";
import Dayone from "../../imagess/dayone.jpg";
import Daytwo from "../../imagess/daytwo.jpg";

import Daythree from "../../imagess/abbs.jpg";
import Dayfour from "../../imagess/fbody.jpg";
import Dayfive from "../../imagess/oblique.jpg";
import Daysix from "../../imagess/legex.jpg";
import Spine from "../../imagess/spineex.jpeg";
import Surya from "../../imagess/surya.jpg";
import Price from '../../BestPrice/Price';

const Body = () => {
  return <>
    <header className='header-bbg'>
      <Navbar />
    </header>
    <section className='third-container'>
      <div className="row row-cols-1 row-cols-md-3 g-4">
        <div className="col">
          <div className="card h-100">
            <div className="ratio ratio-16x9">
              <iframe src="https://www.youtube.com/embed/IOrmS8vJDQw" title="YouTube video" allowfullscreen></iframe>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card h-100">
            <div className="ratio ratio-16x9">
              <iframe src="https://www.youtube.com/embed/uW4REb7GadE" title="YouTube video" allowfullscreen></iframe>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card h-100 ">
            <div className="ratio ratio-16x9">
              <iframe src="https://www.youtube.com/embed/GTkU4qj6v7g" title="YouTube video" allowfullscreen></iframe>
            </div>
          </div>
        </div>
      </div>
    </section>
    <h1><b>Random Bodywise Exercises</b></h1>
    <div className="row row-cols-1 row-cols-md-3 g-4">
      <div className="col">
        <div className="card h-100">
          <img src={Warmup} className="card-img-top" alt="..." />
          <div className="card-body">
            <h5 className="card-title">Daily-Warm-up Exercise</h5>
            <p className="card-text">Warm-up before you start a workout is a must should be done daily. The main purpose of a warm-up is to prepare your body and mind for strenuous activity Other benefits are like warm-up will loosen your joints which in return will save your muscles less likely to tear or twist in a harmful manner. Warm up raise your body temperature and increase blood flow to your muscles so that during heavy exercises you get the required amount of oxygen, energy, etc.</p>
            <a href="https://youtu.be/-n753qyHsdw" target="_blank" className="btn btn-primary">Know more</a>
          </div>
        </div>
      </div>
      <div className="col">
        <div className="card h-100">
          <img src={Dayone} className="card-img-top" alt="..." />
          <div className="card-body">
            <h5 className="card-title">Day 1 - Arm Exercises</h5>
            <p className="card-text">Tone every muscle in your arms strength will increase results in any workout. Stronger arms make it easier to perform everyday tasks.</p>
            <a href="https://bit.ly/3LqaDbk" target="_blank" className="btn btn-primary">Know more</a>
          </div>
        </div>
      </div>
      <div className="col">
        <div className="card h-100">
          <img src={Daytwo} className="card-img-top" alt="..." />
          <div className="card-body">
            <h5 className="card-title">Day 2 - Cardio Exercises</h5>
            <p className="card-text">Exercise that gets your heart rate up and keeps it up for a prolonged period of time.The physical and mental benefits of this type of exercise are seemingly endless.You will have a stronger cardiovascular system, with more capillaries delivering more oxygen to the cells in your muscles.</p>
            <a href="https://bit.ly/3stCEpO" target="_blank" className="btn btn-primary">Know more</a>
          </div>
        </div>
      </div>
    </div>
    <section className='body-left'>
      <div className="row row-cols-1 row-cols-md-3 g-4">
        <div className="col">
          <div className="card h-100">
            <img src={Daythree} className="card-img-top" alt="..." />
            <div className="card-body">
              <h5 className="card-title">Day 3 - Abs Exercises</h5>
              <p className="card-text">Abdominal muscles help improve posture and balance.</p>
              <a href="https://www.insider.com/ab-workouts" target="_blank" className="btn btn-primary">Know more</a>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card h-100">
            <img src={Dayfour} className="card-img-top" alt="..." />
            <div className="card-body">
              <h5 className="card-title">Day 4 - Full Body Exercise</h5>
              <p className="card-text">Full body exercises will maximize your calorie burn . These workouts will surely help . </p>
              <a href="https://www.self.com/gallery/20-minute-no-equipment-total-body-workout" target="_blank" className="btn btn-primary">Know more</a>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card h-100">
            <img src={Dayfive} className="card-img-top" alt="..." />
            <div className="card-body">
              <h5 className="card-title">Day 5 - Oblique Exercises</h5>
              <p className="card-text">In this section the targeted area is your side fats. The side plank is an overlooked exercises for building strong abs.</p>
              <a href="https://fitnessvolt.com/oblique-exercises/" target="_blank" className="btn btn-primary">Know more</a>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className="row row-cols-1 row-cols-md-3 g-4">
        <div className="col">
          <div className="card h-100">
            <img src={Daysix} className="card-img-top" alt="..." />
            <div className="card-body">
              <h5 className="card-title">Day 6 - Leg Exercises</h5>
              <p className="card-text">Neurological health depends on signals sent by leg muscles. It can boost metabolism as well as help in improving functional strength.</p>
              <a href="https://bit.ly/3sK11zO" target="_blank" className="btn btn-primary">Know more</a>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card h-100">
            <img src={Spine} className="card-img-top" alt="..." />
            <div className="card-body">
              <h5 className="card-title">Day 7 - Strengthen Your Spine </h5>
              <p className="card-text">General execises you can do to Strengthen your spine are as followings.. </p>
              <a href="https://bit.ly/3GYAZ0F" target="_blank" className="btn btn-primary">Know more</a>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card h-100">
            <img src={Surya} className="card-img-top" alt="..." />
            <div className="card-body">
              <h5 className="card-title">Universal Full Body Exercise - Surya Namaskar </h5>
              <p className="card-text">Surya Namaskar or Sun Salutation is a sequence of 12 powerful poses.'The Ultimate Asana', strengthens your back as well as your muscles and brings down blood sugar levels. It also improves metabolism and blood circulation and lot more.</p>
              <a href="https://youtu.be/PC7ctYaQ17E" target="_blank" className="btn btn-primary">Know more</a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className='bloggerr'>
      <div className='container services'>
        <div className='u-title'>
          <h2>Video Section</h2>
        </div>
      </div>
      <div className="row row-cols-1 row-cols-md-3 g-4">
        <div className="col">
          <div className="card h-100">
            <div className="ratio ratio-16x9">
              <iframe src="https://www.youtube.com/embed/vP_nQw1KH3k" title="YouTube video" allowfullscreen></iframe>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card h-100">
            <div className="ratio ratio-16x9">
              <iframe src="https://www.youtube.com/embed/wfX5YGEz7q8" title="YouTube video" allowfullscreen></iframe>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card h-100 ">
            <div className="ratio ratio-16x9">
              <iframe src="https://www.youtube.com/embed/ECHxnCJYrpM" title="YouTube video" allowfullscreen></iframe>
            </div>
          </div>
        </div>
      </div>
    </section>

    <Footer />
  </>;
};

export default Body;
