import React from 'react';
import "./Sleepblog.css";
import Navbar from '../../components/navbar/Navbar';
import Footer from '../../components/footer/Footer';

import Hwork from "../../imagess/hwork.jpg";
import Blight from "../../imagess/blight.jpg";
import Mlevel from "../../imagess/mlevel.jpg";

import Rcount from "../../imagess/rcount.jpg";
import Mphone from "../../imagess/mphone.jpg";
import Aclock from "../../imagess/aclock.jpg";

import Button from '../../components/UI/button/Button';

const Sleepblog = () => {
    return (
        <>
            <header className='header-bbg'>
                <Navbar />
            </header>
            <section className="body-left" id='top'>
                        <h2>Hello and Welcome to 24BEFIT, Package Name: Yogic Balance</h2>
                        <p className='u-text-small u-text-dark'>This 3-month package is designed to provide personalized yoga instruction, tailored to meet the unique needs and goals of each individual. Our experienced yoga instructors will work closely with you to create a customized practice that promotes physical, mental, and emotional well-being.</p>
                   <p><h2>Package Inclusions</h2>

<li>1. <b>Initial Consultation:</b> A 30-minute consultation to discuss your goals, health concerns, and yoga experience.</li>
<li>2. <b>Customized Yoga Practice:</b> A personalized yoga practice plan, tailored to your specific needs and goals.</li>
<li>3. <b>9 Private Yoga Sessions:</b> One-on-one yoga sessions with our experienced instructors, held once a week for 3 months.</li>
<li>4. <b>Yoga Philosophy and Breathing Techniques:</b> Guidance on yoga philosophy, breathing techniques, and meditation practices to enhance your overall well-being.</li>
<li>5. <b>Progress Tracking and Feedback:</b> Regular progress tracking and feedback to ensure you're meeting your goals and to make adjustments to your practice as needed.</li></p>
                   
                   </section>
                    <div className="row row-cols-1 row-cols-md-3 g-4">
                        <div className="col">
                            <div className="card h-100">
                                <img src={Blight} className="card-img-top" alt="..." />
                                <div className="card-body">
                                    <h5 className="card-title"><b><u>Live Online Classes:</u></b></h5>
                                    <p className="card-text">Real-time classes with interactive feedback.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card h-100">
                                <img src={Mlevel} className="card-img-top" alt="..." />
                                <div className="card-body">
                                    <h5 className="card-title"><b><u>Yoga and Meditation:</u></b></h5>
                                    <p className="card-text">Classes combining yoga with meditation practices.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                <div id="container">
                    <section className="body-left">
                        <h1><b>Package Validity

3 months from the date of purchase</b></h1>
                        <p><h2>Cancellation Policy</h2>

<li>- 50% refund if cancelled within 3 days of purchase</li>
<li>- 25% refund if cancelled within 1 week of purchase</li>
<li>- No refund if cancelled after 1 week of purchase</li></p>

                        <p><h3>Terms and Conditions</h3>

<li>- The package is non-transferable and cannot be shared with others.</li>
<li>- The package is valid for 3 months from the date of purchase.</li>
<li>- Cancellation policy applies as stated above.</li>
<li>- Our yoga instructors reserve the right to modify or cancel sessions due to unforeseen circumstances.</li></p>
                    </section>
                    <div className='header-cta'>
                        <Button text={"Buy@Rs11,000 Only"} btnClass={"btn-orange"} href="https://rzp.io/i/TazcUbLi" />
                        <Button text={"Home"} btnClass={"btn-orange"} href="https://fitbeautie.com/" />
                        <Button text={"Top pg"} btnClass={"btn-orange"} href="#top" />
                    </div>
                </div>
                <br />
            <Footer />
        </>
    )
}

export default Sleepblog