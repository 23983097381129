import React, { useState } from "react";
import Footer from "../footer/Footer";
import Navbar from "../navbar/Navbar";
import "./FitnessPlan.css";
import Price from '../../BestPrice/Price';

const FitnessPlan = () => {
    return (
        <>
        <header className='header-bbg'>
    <Navbar/>
    </header>
            <Price/>
            <Footer/>
        </>
    );
};

export default FitnessPlan;
