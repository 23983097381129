import React from 'react'
import "./Price.css"
import Plan1 from "../imagess/orifit.jpg";
import Plan2 from "../imagess/supfit.jpg";
import Plan3 from "../imagess/allone.jpg";
import Plan4 from "../imagess/interfit.jpg";
import Plan5 from "../imagess/tintofit.jpg";
import Plan6 from "../imagess/intfit.jpg";

const Price = () => {
  return <>
    <section className='fitindia-container'>
      <div className='u-title'>
            <h2>Best Online Fitness and Diet Plan Packages</h2>
            <p className='u-text-small u-text-dark'>  We've got the most affordable prices online .</p>
          </div>
    <div className="row row-cols-1 row-cols-md-3 g-4">
  <div className="col">
    <div className="card h-100">
      <img src={Plan1} className="card-img-top" alt="..."/>
      <div className="card-body">
        <h5 className="card-title"><b><u>Plan 1 - 100% Organic Diet Plan</u></b></h5>
        <p className="card-text">FitBeautie is providing<br/>
        <li>Diet plan for 31 Days.</li>
        <li>3 Weekly counselling. </li>
        <li>Daily reminders through WhatsApp.</li>
        <li>100% Supplement free Diet Plan.</li>
        </p>
        <a href="https://rzp.io/l/Kps0GTJ75B" target="_blank" className="btn btn-primary">BUY Now</a>
      </div>
      <div className="card-footer">
        <small className="text-muted"> Rs 1799 only</small>
      </div>
    </div>
  </div>
  <div className="col">
    <div className="card h-100">
      <img src={Plan2} className="card-img-top" alt="..."/>
      <div className="card-body">
        <h5 className="card-title"><b><u>Plan 2 - Diet Plan With Supplement</u></b></h5>
        <p className="card-text">FitBeautie is providing <br/>
        <li>Diet plan for 31 days.</li>
        <li>2 Weekly counselling.</li>
        <li>Supplement Included.</li></p>
        <a href="https://rzp.io/l/1CwXFod" target="_blank" className="btn btn-primary">BUY Now</a>
      </div>
      <div className="card-footer">
        <small className="text-muted">Rs 999 only</small>
      </div>
    </div>
  </div>
  <div className="col">
    <div className="card h-100">
      <img src={Plan3} className="card-img-top" alt="..."/>
      <div className="card-body">
        <h5 className="card-title"><b><u>Plan 3 - All Round Workout Package. </u></b></h5>
        <p className="card-text">FitBeautie offers you <br/>
        <li>Daily exercise 1 hr for 31 days.</li>
        <li>Diet Plan for 31 days.</li>
        <li>Weekly Fitness & Food counselling.</li>
        </p>
        <a href="https://rzp.io/l/LjkPFF6m4" target="_blank" className="btn btn-primary">BUY Now</a>
      </div>
      <div className="card-footer">
        <small className="text-muted">Rs 999 only</small>
      </div>
    </div>
  </div>
</div>
    </section>
    <section className='fitindia-container'>
      <div className='u-title'>
           </div>
    <div className="row row-cols-1 row-cols-md-3 g-4">
  <div className="col">
    <div className="card h-100">
      <img src={Plan6} className="card-img-top" alt="..."/>
      <div className="card-body">
        <h5 className="card-title"><b><u>Plan 4 - Weight Loss Package (Guaranty Results)</u></b></h5>
        <p className="card-text">FitBeautie offers here <br/>
        <li>Daily exercise 1 hr for 31 days.</li>
        <li>Diet Plan for 31 days.</li>
        <li>3 Weekly Fitness & Food counselling.</li>
        <li>Weight loss Drinks & Food recipes</li>
       </p>
        <a href="https://rzp.io/l/epAFkzkTkx" target="_blank" className="btn btn-primary">BUY Now</a>
      </div>
      <div className="card-footer">
        <small className="text-muted"> Rs 1,999 (without supplements) <br/> Rs 5999 (with supplements)</small>
      </div>
    </div>
  </div>
  <div className="col">
    <div className="card h-100">
      <img src={Plan5} className="card-img-top" alt="..."/>
      <div className="card-body">
      <h5 className="card-title"><b><u>Plan 5 - Weight Gain Package (Guaranty Results)</u></b></h5>
        <p className="card-text">FitBeautie offers here <br/>
        <li>Daily exercise 1 hr for 31 days.</li>
        <li>Diet Plan for 31 days.</li>
        <li>3 Weekly Fitness & Food counselling.</li>
        <li>Weight gain Drinks & Food recipes</li>
       </p>
        <a href="https://rzp.io/l/wZIuqNVg3" target="_blank" className="btn btn-primary">BUY Now</a>
      </div>
      <div className="card-footer">
        <small className="text-muted"> Rs 1,499 (without supplements) <br/> Rs 4999 (with supplements)</small>
      </div>
    </div>
  </div>
  <div className="col">
    <div className="card h-100">
      <img src={Plan4} className="card-img-top" alt="..."/>
      <div className="card-body">
        <h5 className="card-title"><b><u>Plan 6 - Interview Fitness Package</u></b></h5>
        <p className="card-text">Fitness confidence high & mental stress out. <br/>
        <li>Daily exercise 1hr session for 7 days.</li>
        <li>Daily diet for 7 days with memory boosting foods.</li> 
<li>3 Music Therappy.</li>
        </p>
        <a href="https://rzp.io/l/mLkJkuLtvd" target="_blank" className="btn btn-primary">BUY Now</a>
      </div>
      <div className="card-footer">
        <small className="text-muted">Rs 999 only</small>
      </div>
    </div>
  </div>
</div>
    </section>
  </>
}

export default Price