import React from 'react'
import './Team.css';
import Sohan from '../../imagess/smimg.jpg';
import Priyanka from '../../imagess/pkimg.jpg';

const Team = () => {
  return (
    <>
    <section id="ourteam" className="team team-bg py-5">
	  <div className="container">

		<div className="section-title">
		  <p className="main-team-subheading">Team</p>
		  <p className="main-team-heading">Our Team</p>
		</div>

		<div className="row">

		  <div className="col-lg-6">
			<div className="member d-flex align-items-start">
			  <div className="pic"><img src={Sohan} className="img-fluid" alt=""/></div>
			  <div className="member-info">
				<p className="member-heading">SOHAN MISHRA</p>
				<span>Head Fitness Trainer</span>
				<p className="member-para">ID - FITBEE2022110</p>
			  </div>
			</div>
		  </div>

		  <div className="col-lg-6 mt-4 mt-lg-0">
			<div className="member d-flex align-items-start">
			  <div className="pic"><img src={Priyanka} className="img-fluid" alt=""/></div>
			  <div className="member-info">
				<p className="member-heading">PRIYANKA KASHYAP</p>
				<span>Head Nutritionist</span>
				<p className="member-para">ID - FITBEE202211</p>
			  </div>
			</div>
		  </div>

		</div>

	  </div>
	</section>
    </>
  )
}

export default Team