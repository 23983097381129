import React from 'react';
import "./Blogs.css";
import Navbar from '../components/navbar/Navbar';
import Footer from '../components/footer/Footer';




const Choosetheright = () => {
    return (
        <>
            <header className='header-bbg'>
                <Navbar />
            </header>
            <section className='body-left'>
                <div class="bg-gray-200 min-h-screen">
                    <div className="max-w-screen-xl mx-auto px-4 py-16">
                        <div className="flex flex-col flex-wrap md:flex-row md:-mx-3">
                            <div className="w-full lg:w-3/4 mb-8 lg:mb-0">
                                <div className="p-8 bg-white">
                                    <h1 className=" font-bold text-4xl md:text-5xl pb-8 leading-tight">The right person</h1>
                                    <div className="bg-gray-200 mb-6 inline-block py-1 px-2 italic text-sm">Posted on: <time datetime="2022-02-24 13:30">Feb 28, 2022</time> by <b><u>fitbeautie Blogger Team</u></b></div>
                                    <div className="leading-relaxed">
                                        <h2 className="font-bold text-3xl pb-6 leading-tight">Someone who finds you imperfectly perfect for them</h2>
                                        <p className="pb-6">In uncomfortable situations, how a person behaves/reacts shows a lot about their character.Avoid anyone who does not communicate in times of conflicts, no matter how small.</p>

                                        <h2 className="font-bold text-3xl pb-6 leading-tight">Choose a connection that adds value to your life while also allowing you to add to it. Partner should be judged on the basis of their humanity, not their status, position, or wealth.</h2>
                                        <p className="pb-6">Choose how long they've been with you, how they deal with your negative moods, how supportive they are emotionally, how they deal with their burdens, how safe you feel in their presence, and whether they respect your "no" even though they're anxious to hear "yes." Is it important to them what you like or dislike?</p>
                                        <p className="pb-6">If you want to know if someone loves you or not, look at how lively and pleased he/she is with you.</p>
                                        <h2 className=" font-bold text-3xl pb-6 leading-tight">There are several factors to consider while deciding whether a guy or a woman is the best life buddy/companion.</h2>
                                        <p className=" pb-6"> <li> Each other is each other's good friend, does not take any condition, likes to be together with each other.</li>
                                            <li> It is easy to communicate with each other, and they can confess anything openly without fear of being suspected or despised by the other party.</li>
                                            <li> When there are different opinions or disputes, you can solve them together instead of waiting for them to attack later.</li>
                                            <li> Get along with each other funny, often have laughter, in many aspects of life will be treated with humor.</li>
                                            <li> You know each other advantages and disadvantages still accept each other. This shows mature association from both sides.</li>
                                            <li> Sometimes there will be romantic feelings, but most of the time, you are very satisfied and free.</li>
                                        </p>
                                        <p className=" pb-6">Now, let me conclude by saying that you should have faith in yourself that whichever you choose, your presence in their lives will not only bring value to their lives, but you will also build empires together. And you both offer each other purpose. And you'll both be each other's strength at some point. I hope that this blog inspires you to seek out a decent human who loves, supports, cares for, and respects you.</p>

                                       

                                    </div>                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default Choosetheright;
