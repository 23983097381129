export const careers = [

    {
        id: 1,
        title:"Beautician",
        answer: "We're looking for experts in the field of beauty as Facial/Makeup Artists/Hair experts who are willing to give home services either full-time or as part-time job for extra income with full dedication. You will also get the opportunity of promotions while working on the basis of ratings and work efficency.",
    },

    {
        id: 2,
        title:"Nutritionist/ Dietitian",
        answer: "Your main role include analyzing clients health status,food intake ,fitness/health goals and tracking clients' dietary intake and progress and educating them on how to maintain a balanced diet for healthy living.You will also get the opportunity of increment/partnership while working on the basis of ratings , work efficency and service quality.",
    },

    {
        id: 3,
        title:"Content Writing, Blog Writing",
        answer: "We're looking for a content/blog writer to join our editorial team and add our websites with new articles, news stories,fitness/health/beauty blog posts.Promotion on the basis of work quality and efficency.",
    },

    {
        id: 4,
        title:"Fitness Trainer / Yoga Trainer ",
        answer: "We need expertise in the field of Fitness or Physiotherapy or Yoga or Zumba. For giving zoom sessions or home training either full-time or part-time job. Increment/promotion is entirely based on the quality of the work and the efficacy.",
    },

    {
        id: 5,
        title:"Non-voice / Voice process",
        answer: "**Non-voice**-you have to perform service like handling the customers through emails, messages etc. where you have to use written languages.This process (non-voice) requires the skill for writing or typing and the ability to understand the type of query for making the right processing. **Voice Process** is phone call handling from customers and resolving the issues.You will be supporting the query and do the needful plus will report to the team leader. Key skill you will require are clarity in communicating,patience,problem solving attitude and good listener. ",
    },

    {
        id: 6,
        title:"Backend Data Entry",
        answer: "Back office Computer work where you have to compiles, and sorts documents for data entry.Transfers information from paper formats into computer files using keyboards, data recorders, or optical scanners.Entering customer and account data from source documents within time limits.Reviewing data for deficiencies or errors, correcting any incompatibilities and checking output. Key skills we are looking in are 1.Excellent attention to detail. 2.Ability to multitask effectively. 3.Strong written and verbal communication skills.  4.Ability to perform repetitive tasks with a high degree of accuracy.",
    },

    {
        id: 7,
        title:"Graphic Designer & Video Editor",
        answer: "We're looking for a  graphic designer & video editor to join our team. He/She must have knowledge of layouts, graphic fundamentals, typography, video editing ; must have the ability to storyboard or translate ideas to team members too.Your work role will be to design creative Ad campaign visuals, posters, banners, YouTube videos,vector animation.Increment/promotion is entirely based on the quality of the work and the efficacy.",
    },

    {
        id: 8,
        title:"Event Executive",
        answer: "Your work will be to ensure staff is working together as a team.Assist management in hiring, training, scheduling, evaluating, counseling, disciplining, and motivating and coaching employees. Ensure adherence to quality expectations and standards. Perform other reasonable job duties as requested.Increment/promotion is entirely based on the quality of the work and the efficacy.Key Skills we are looking in are  Event Planning Skills · Organizational Skills · Communication · Networking · Calm under pressure · Problem Solving mindset. ",
    },

    {
        id: 9,
        title:"Marketing Executive",
        answer: "We're looking for a person who can develop a fair understanding about our services to both our clients and employees. Field-work experience in marketing is must. You must have strong communication skills , ability to influence and negotiate with others and most importantly the capability to flourish in a competitive environment.You do have an opportunity of partnership with feisbodyhair which depends on your work dedication and other parameters which you will know at the time of joining us.",
    },

]