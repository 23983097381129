import React from 'react';
import "./Blogs.css";
import Navbar from '../components/navbar/Navbar';
import Footer from '../components/footer/Footer';


import Nutform from "../imagess/ntform.jpg";
import Hwework from "../imagess/fitpllan.jpg";
import Advntimg from "../imagess/fitchall.jpg";
import FaceAdd from "../imagess/faceadd.jpg";


import Knowskin from "../imagess/obagi-mobile.jpg";
import Warmwork from "../imagess/warrrm.jpg";
import Bumexer from "../imagess/bumexer.png";





const Lifestyle = () => {
    return (
        <>
            <header className='header-bbg'>
                <Navbar />
            </header>
            <section className='body-left'>
                <div class="bg-gray-200 min-h-screen">
                    <div className="max-w-screen-xl mx-auto px-4 py-16">
                        <div className="flex flex-col flex-wrap md:flex-row md:-mx-3">
                            <div className="w-full lg:w-3/4 mb-8 lg:mb-0">
                                <div className="p-8 bg-white">
                                    <h1 className=" font-bold text-4xl md:text-5xl pb-8 leading-tight">Lifestyle</h1>
                                    <div className="bg-gray-200 mb-6 inline-block py-1 px-2 italic text-sm">Posted on: <time datetime="2022-02-24 13:30">March 04, 2022</time> by <b><u>fitbeautie Blogger Team</u></b></div>
                                    <div className="leading-relaxed">
                                        <h2 className="font-bold text-3xl pb-6 leading-tight">How you begin and end your day ?</h2>
                                        <p className="pb-6">When we consider a healthy lifestyle, we are referring to how you begin and end your day. It's also about how you handle things during the day, such as what you eat, how you stay energized, and how you deal with conflict and stress.</p>

                                        <h2 className="font-bold text-3xl pb-6 leading-tight">The way you carry out your plans/projects is all a part of your lifestyle.</h2>
                                        <p className="pb-6">It's a blog about tips that will help you keep your stress low & your output high, as well as a few top names you can look up to learn about healthy living, healthy eating, and good communication.</p>
                                       <h2 className=" font-bold text-3xl pb-6 leading-tight">Tips you should follow : </h2>
                                        <p className=" pb-6"> <li>Drink a glass of warm water first thing in the morning.</li>
                                            <li> Exercise and a bath should be done every day.</li>
                                            <li> Breakfast should include items that are high in energy.</li>
                                            <li> Wear clothes that are neatly ironed and have a pleasant fragrance.</li>
                                            <li> Making a full day schedule ahead of time (using applications to remind you of your schedule for that day) will save you a lot of time.</li>
                                            <li>Take a drink every now and then to maintain your energy up and your immunity strong.</li>
                                            <li> Do carry your skin care products in your pocket at all times so you can look fresh and appealing whenever you want.</li>
                                            <li> Do learn simple stretching chair exercises to relax your body while working in the office.</li>
                                            <li>Maintain a clear and simple tone in your conversations and stay away from persons that are negative.</li>
                                            <li>Evenings can be spent to meet up with friends, read, or go for a walk (choose according to your mood with a goal to relax )</li>
<li>Always eat a small dinner.</li>
<li>Before bed, you can take a shower (It helps to relax).</li>
<li>Do have a glass of milk or warm water 10 minutes before bedtime.</li>
<li>Drink plenty of water throughout the day ( 7 to 8 glasses of water per a day).</li>
<li>Make a playlist of music that will motivate or relax you.</li>
</p>
<p>You can look up some top names to see how they spend their days like P V Sindhu , Roshni Malhotra , Nithin Kamath and Palak kohli</p>
                                       
                                        <section className='fitindia-container'>
                                            <div className="row row-cols-1 row-cols-md-3 g-4">
                                                <div className="col">
                                                    <div className="card h-100">
                                                        <img src={Knowskin} className="card-img-top" alt="..." />
                                                        <div className="card-body">
                                                            <h5 className="card-title">How do i figure out my skin type ?</h5>
                                                            <p className="card-text">Knowing your skin type is crucial in determining which products would be the most beneficial.Treating the wrong skin type can lead to irritated skin, breakouts and premature aging. Choosing the correct skin care routine for your skin type is essential.</p>
                                                            <a href="https://bit.ly/34I47fc" target="_blank" className="btn btn-primary">Know more</a>
                                                        </div>
                                                        <div className="card-footer">
                                                            <small className="text-muted">Last updated Feb 10,2022</small>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="card h-100">
                                                        <img src={Bumexer} className="card-img-top" alt="..." />
                                                        <div className="card-body">
                                                            <h5 className="card-title">Exercises for a bigger bum</h5>
                                                            <p className="card-text">Exercises and Strategies for a Bigger, Firmer Butt.The time it takes to grow your butt varies depending on what approach you take. If you decide to use diet and exercises, it might take between.. </p>
                                                            <a href="https://www.cosmopolitan.com/uk/body/fitness-workouts/a27691179/how-to-get-a-bigger-bum/" target="_blank" className="btn btn-primary">Know more</a>
                                                        </div>
                                                        <div className="card-footer">
                                                            <small className="text-muted">Last updated Feb 10,2022</small>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="card h-100">
                                                        <img src={Warmwork} className="card-img-top" alt="..." />
                                                        <div className="card-body">
                                                            <h5 className="card-title">Warm Up your Body</h5>
                                                            <p className="card-text">Warm Up is widely believed to prepare the muscles for vigorous actions and to prevent muscle cramps and injury due to overexertion.Athletes, singers, actors and others warm up before stressing their muscles.</p>
                                                            <a href="https://www.womenshealthmag.com/fitness/g26554730/best-warm-up-exercises/" target="_blank" className="btn btn-primary">Know more</a>
                                                        </div>
                                                        <div className="card-footer">
                                                            <small className="text-muted">Last updated Feb 10,2022</small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>


                                    </div>                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default Lifestyle;
