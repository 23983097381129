import React from 'react'
import '../News/News.css'
import Phone from "../../imagess/phonee.jpg"
import Signup from '../Signup/Signup'

const News = () => {
  return (
    <>

      <section className="wrapper">
        <div className="container-fostrap">
          <div>
            <h1 className="heading">
              Membership Package - Get membership
            </h1>
            <Signup/>
          </div>
          </div>
      </section>
    </>
  )
}

export default News