import React from 'react';
import "./Exerciseblog.css";
import Navbar from '../../components/navbar/Navbar';
import Footer from '../../components/footer/Footer';

import Fitwork from "../../imagess/ffit.jpg";
import Fitlight from "../../imagess/fiit.jpg";
import Fitlevel from "../../imagess/fitt.jpg";

import Livee from "../../imagess/livee.jpg";
import Pland from "../../imagess/pland.jpg";
import Fourne from "../../imagess/fourne.jpg";

import Button from '../../components/UI/button/Button';

const Exerciseblog = () => {
    return (
        <>
            <header className='header-bbg'>
                <Navbar />
            </header>
            <section className="body-left" id='top'>
                <h2>Hello and Welcome to 24BEFIT, Package Name: Yogic Harmony</h2>
                <p className='u-text-small u-text-dark'><h3>Package Overview:</h3>

This 3-month package is designed to provide personalized yoga instruction, tailored to meet the unique needs and goals of each individual. Our experienced yoga instructors will work 
closely with you to create a customized practice that promotes physical, mental, and emotional well-being.</p>
                <p><h3>Package Inclusions:</h3>

<li>1. <b>Initial Consultation:</b> A 30-minute consultation to discuss your goals, health concerns, and yoga experience.</li>
<li>2. <b>Customized Yoga Practice:</b> A personalized yoga practice plan, tailored to your specific needs and goals.</li>
<li>3. <b>12 Private Yoga Sessions:</b> One-on-one yoga sessions with our experienced instructors, held once a week for 3 months.</li>
<li>4. <b>Yoga Philosophy and Breathing Techniques:</b> Guidance on yoga philosophy, breathing techniques, and meditation practices to enhance your overall well-being.</li>
<li>5. <b>Progress Tracking and Feedback:</b> Regular progress tracking and feedback to ensure you're meeting your goals and to make adjustments to your practice as needed.</li>
<li>6. <b>Access to Exclusive Yoga Content:</b> Access to exclusive yoga content, including videos, articles, and tips, to support your practice.</li></p>

<h2>Themed Classes</h2>

<li>1. Stress Relief: Classes focused on relaxation and stress reduction.</li>
<li>2. Back Care: Classes targeting back health and spinal alignment.</li>
<li>3. Weight Management: Classes designed for weight loss and management.</li>
<li>4. Yoga for Emotional Balance: Classes focusing on emotional well-being.</li>
<li>5. Yoga for Sleep: Classes designed to improve sleep quality.</li>
<h1><b>Package Validity:3 months from the date of purchase</b></h1>
                    <p>

<h3>Cancellation Policy:</h3>

<li>- 50% refund if cancelled within 3 days of purchase</li>
<li>- 25% refund if cancelled within 1 week of purchase</li>
<li>- No refund if cancelled after 1 week of purchase</li> </p>
                    
                    <div className="row row-cols-1 row-cols-md-3 g-4">
                        <div className="col">
                            <div className="card h-100">
                                <img src={Livee} className="card-img-top" alt="..." />
                                <div className="card-body">
                                    <h5 className="card-title"><b><u>Ashtanga Yoga: Fast-paced, physically demanding practice with set sequences.</u></b></h5>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card h-100">
                                <img src={Pland} className="card-img-top" alt="..." />
                                <div className="card-body">
                                    <h5 className="card-title"><b><u>Yoga for Sleep: Classes designed to improve sleep quality.</u></b></h5>
                                </div>
                            </div>
                        </div>
                        </div>
                </section>
                <div className='header-cta'>
                    <Button text={"Buy@Rs15,000 Only"} btnClass={"btn-orange"} href="https://rzp.io/i/xgoxDBCW" />
                    <Button text={"Home"} btnClass={"btn-orange"} href="https://fitbeautie.com/" />
                    <Button text={"Top pg"} btnClass={"btn-orange"} href="#top" />
                </div>
            <br />
            <Footer />
        </>
    )
}

export default Exerciseblog