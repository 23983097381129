import React from 'react';
import "./Blogs.css";
import Navbar from '../components/navbar/Navbar';
import Footer from '../components/footer/Footer';


import Nutform from "../imagess/ntform.jpg";
import Hwework from "../imagess/fitpllan.jpg";
import Advntimg from "../imagess/fitchall.jpg";
import FaceAdd from "../imagess/faceadd.jpg";


const Angermanagement = () => {
  return  ( 
  <>
  <header className='header-bbg'>
    <Navbar/>
    </header>
    
      <section className='body-left'> 
      <div class="bg-gray-200 min-h-screen">
  <div className="max-w-screen-xl mx-auto px-4 py-16">
    <div className="flex flex-col flex-wrap md:flex-row md:-mx-3">
      <div className="w-full lg:w-3/4 mb-8 lg:mb-0">
          <div className="p-8 bg-white">
            <h1 className=" font-bold text-4xl md:text-5xl pb-8 leading-tight">Anger Management</h1>
            <div className="bg-gray-200 mb-6 inline-block py-1 px-2 italic text-sm">Posted on: <time datetime="2022-02-24 13:30">Feb 24, 2022</time> by <b><u>fitbeautie Blogger Team</u></b></div>
            <div className="leading-relaxed">
              <h2 className="font-bold text-3xl pb-6 leading-tight">"Control me if you can "said anger</h2>
              <p className="pb-6"><strong>“Anger is an overreaction to a situation.”</strong>Anger causes mental illness like IED an impulse-control disorder etc. And I know you don’t want to fall in such a list of illnesses ever.
Act clever and gain the benefit that I am trying to convey to you through this blog.
</p>
              <h2 className="font-bold text-3xl pb-6 leading-tight">“If you can control ,anger no one can defeat you .“</h2>
              <p className="pb-6">To prove my statement I will go with a wrestler viewpoint. A wrestler doesn’t retain their anger but manages it to gain points.
A single extra point can change the whole game.
         Right. 
Similarly, we will manage our anger to gain points in our own life. In more simple words we are trying to benefit from our bad energy.

Fully getting rid of anger is not possible. Only saint achieves such a level of power and one in a hundred wishes to be a saint.We are normal and we will handle it in a normal manner and will focus on simple strategies that will apply in our day to day life and will try to achieve maximum benefit without disturbing our daily routines.
</p>
              <p className="pb-6">Anger is a devil's action. When you are angary you look like a devil. You loose sense and take unappropriated action that only leads to bad output or dead end.
You overact to a situation where you could have used your brain and got rid off the situation but your anger took the control, got your brain thinking function down.Which turned the whole situation against you.
</p>
<p className="pb-6">So we don't make future blunders let focus on the facts that will give you full control to your own brain during angary mood.
</p>
              <h2 className=" font-bold text-3xl pb-6 leading-tight">Let us check the points that you should start applying:</h2>
              <p className=" pb-6">
                  <li>Anulom Vilom Pranayama and lots of water to drink.</li>
                  <li>In Puran kings used to chant mantras regularly to control theirs angers you too can choose any mantras.</li>
                  <li>Predict the action that can lead you to anger beforehand and avoid that moment to happen in the future or picture a stop sign.</li>
                  <li>Stop connecting your arguments with ego. First, calm your mind and try to lead that argument into a normal conversation and get the problem shorted right their. Try "mutual settlement policy".</li>
                  <li>For more good suggestion talk to aging parents.</li>
                  <li>Your food habit and sleep also helps to manage your anger.so take a good sleep and choose wisely what you eat.</li>
                  <li>Lastly, learn to forgive and say “sorry” for your mistakes/wrongs done.</li>
              </p>
              <h2 className="font-bold text-3xl pb-6 leading-tight">calm mind, early prediction and a positive approach will direct you on the road of anger.</h2>
              <p className=" pb-6">If you seriously go with the points above. They are enough to bring that change and control in your anger that you are looking for in the future and your personality. No class, blog or video will help you until you don’t bring that change from within. In the end your choice matters either you will grow high or put yourself down.</p>
</div>
              </div>
        </div>
        </div>
        </div>
        </div>
        </section>
      <Footer/>      
  </>
  );
};

export default Angermanagement;
