import React, { Component } from "react";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";

  class Skinage extends Component {
    constructor() {
      super();
      this.state = {
        name: "React"
      };
      this.onValueChange = this.onValueChange.bind(this);
      this.formSubmit = this.formSubmit.bind(this);
    }
  
    onValueChange(event) {
      this.setState({
        selectedOption: event.target.value
      });
    }
  
    formSubmit(event) {
      event.preventDefault();
      console.log(this.state.selectedOption)
    }
  
  render() 
  {
    return (
      <>
<header className='header-bbg'>
    <Navbar/>
    </header>
      <section>
      <h1>Hey,it takes less than 60s . Want to Know the real age of your skin ?</h1>
      <div className="row">
                                            <div className="col-12 contact-input-feild">
                                                <input
                                                    type="text"
                                                    name="name"
                                                    id=""
                                                    className="form-control"
                                                    placeholder="name"
                                                                                         
                                                />
                                            </div>
                                           </div>
        <div className="mb-3 pt-0">
        <input
          type="text"
          placeholder="Your age"
          name="name"
          className="px-3 py-3 placeholder-gray-400 text-gray-600 relative bg-white bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full"
          required
        />
        </div>
        <div className="mb-3 pt-0">
        <input
          type="text"
          placeholder="Your mobile"
          name="name"
          className="px-3 py-3 placeholder-gray-400 text-gray-600 relative bg-white bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full"
          required
        />
        </div>
        <div className="mb-3 pt-0">
        <input
          type="text"
          placeholder="Your email"
          name="name"
          className="px-3 py-3 placeholder-gray-400 text-gray-600 relative bg-white bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full"
          required
        />
        </div>
        <h3>Choose the options according to your skin values</h3><br/>
        <h5>1. Do you see smile lines from the corner of your Nostrils to your mouth </h5>
      <form onSubmit={this.formSubmit}>
        <div className="radio">
          <label>
            <input
              type="radio"
              value="No"
              checked={this.state.selectedOption === "No"}
              onChange={this.onValueChange}
            />
            No
          </label>
        </div>
        <div className="radio">
          <label>
            <input
              type="radio"
              value="Yes,soft lines"
              checked={this.state.selectedOption === "Yes,soft lines"}
              onChange={this.onValueChange}
            />
            Yes,soft lines
          </label>
        </div>
        <div className="radio">
          <label>
            <input
              type="radio"
              value="Yes,very clear lines"
              checked={this.state.selectedOption === "Yes,very clear lines"}
              onChange={this.onValueChange}
            />
            Yes,very clear lines
          </label>
        </div>
              </form>
      <h5>2. Do you see crow's-feet at the outer corner of your eyes ? </h5>
      <form onSubmit={this.formSubmit}>
        <div className="radio">
          <label>
            <input
              type="radio"
              value="No"
              checked={this.state.selectedOption === "No"}
              onChange={this.onValueChange}
            />
            No
          </label>
        </div>
        <div className="radio">
          <label>
            <input
              type="radio"
              value="Yes,slight lines"
              checked={this.state.selectedOption === "Yes,slight lines"}
              onChange={this.onValueChange}
            />
            Yes,slight lines
          </label>
        </div>
        <div className="radio">
          <label>
            <input
              type="radio"
              value="Yes,marked lines"
              checked={this.state.selectedOption === "Yes,marked lines"}
              onChange={this.onValueChange}
            />
            Yes,marked lines
          </label>
        </div>
      </form>
      <h5>3. Do you have Bags under your eyes ? </h5>
      <form onSubmit={this.formSubmit}>
        <div className="radio">
          <label>
            <input
              type="radio"
              value="No"
              checked={this.state.selectedOption === "No"}
              onChange={this.onValueChange}
            />
            No
          </label>
        </div>
        <div className="radio">
          <label>
            <input
              type="radio"
              value="Small ones"
              checked={this.state.selectedOption === "Small ones"}
              onChange={this.onValueChange}
            />
            Small ones
          </label>
        </div>
        <div className="radio">
          <label>
            <input
              type="radio"
              value="Distinct Bags"
              checked={this.state.selectedOption === "Distinct Bags"}
              onChange={this.onValueChange}
            />
            Distinct Bags
          </label>
        </div>
        <div>
          Selected option is : {this.state.selectedOption}
        </div>
        <button className="btn btn-default" type="submit">
          Submit
        </button>
      </form>
    
    
    
</section>
<Footer/>
</>
);
  } 
}
export default Skinage;
