import React from 'react';
import './Header.css';
import Button from '../UI/button/Button';
import "../UI/button/Button.css";
const Header = () => {
  return <div>

    <section id='header'>
      <div className='container header'>
        <div className='header-left'>
          <h1>
            <h2>Welcome to 24Befit! </h2>
            <p><h3>Our goal is to enable you to reach your full potential in terms of your physical, mental,
               and emotional health so that you can flourish in all area of your life. Everybody should be
                able to live a balanced, healthy, and satisfying life, and we're dedicated to making that happen.</h3></p>
                 <p><h1>At 24befit,</h1><h3>We think that everyone can develop into an alpha male or female—strong, self-assured, and empowered. 
              To begin your path to self-actualization and optimal wellness, try our services now.</h3></p>
          </h1>
          <p className='u-text-small u-text-light'></p>
          <div className='header-cta'>
          <Button text={"About Us"} btnClass={"btn-orange"} href={"/Aboutus"} />
            <Button text={"Premium Service"} btnClass={"btn-orange"} href={"/Pservice"} />
            <Button text={"Blogs"} btnClass={"btn-orange"} href={"#sleepservice"} />
                  </div>

        </div>
        {/*
        <div className='header-right'>
          <img src={headimage} alt='image' />
        </div> 
      </div>
      
      <div className='floating-icon'>
        <a href='#services'>
          <BsMouse color="#fff" size={25} className="mouse" />
        </a>
      </div> */}
      </div>
    </section>
  </div>;
};

export default Header;
