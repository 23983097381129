import React from "react";
import './Footer.css';
import {
FaMapMarkedAlt,
FaPhoneAlt,
FaEnvelope,
FaGlobe,
} from "react-icons/fa";
import {BsWhatsapp} from "react-icons/bs";

const Footer = () => {
  return (

      <footer id="footer">
        <div className="container footer">
         {/* <div className="footer-box">
          <h4>Useful Links</h4>
          <div className="footer-links">
             <a href="/face">&bull; Face</a>
            <a href="/body">&bull; Body</a>
           <a href="/hair">&bull; Hair</a>
            <a href="/foodplan">&bull; Diet Plans</a>
            <a href="/fitnessplan">&bull; Fitness Plans</a>
          </div>
          </div> */}
            <div className="footer-box">
            <h4>Support</h4>
          <div className="footer-links">
          <a href="/career">&bull; Careers</a>
          {/*  <a href="#">&bull; About</a>
            <a href="#">&bull; Events</a>
            
            <a href="#">&bull; Payment</a>
            <a href="#">&bull; Downloads</a> */}
          </div>
            </div>
            <div className="footer-box">
            <h4>Contact Us</h4>
          <div className="footer-contact">
          <p> <FaMapMarkedAlt/> {'\u00A0'} Address : Uttar Pradesh & Jharkand </p>
          <p> <FaEnvelope/> {'\u00A0'} Email : contactus@24befit.com</p>
          <p> <FaGlobe/> {'\u00A0'} Website : http://24befit.com</p>
             </div>
            </div>
            <div className="footer-box">
              {/* <img src="#" alt="logo" /> */}
              <p className="u-small-text">
                &copy; Copyright 2025. 24BEFIT.com
              </p>
            </div>
                      </div>
      </footer>
  );
};

export default Footer;
