import React from 'react';
import "./Hpwone.css";
import Navbar from '../../components/navbar/Navbar';
import Footer from '../../components/footer/Footer';
import Honeprice from "../../imagess/honeprice.jpeg";
import Button from '../../components/UI/button/Button';

const Hpwone = () => {
    return (
        <>
            <header className='header-bbg'>
                <Navbar />
            </header>
            
            <section class="about-section">
        <div class="container">
            <div class="row">                
                <div class="content-column col-lg-6 col-md-12 col-sm-12 order-2">
                    <div class="inner-column">
                        <div class="sec-title">
                            <span class="title">24BeFit</span>
                            <h2>Holistic Wellness Package One</h2>
                        </div>
                        <div class="text"><h1>Yoga Classes:</h1> <li>36 sessions of yoga classes (2 sessions per week for 3 months) tailored to your needs and goals. </li>Our experienced yoga instructors will guide you through a series of postures, breathing techniques, and relaxation methods to improve your physical, mental, and emotional well-being.</div>
                        <div class="btn-box">
                            <a href="" class="theme-btn btn-style-one">Value: Rs 6,000</a>
                        </div>
                      <div class="text">
                      <h1>Personalized Diet Plan:</h1> 
                      <li>A customized diet plan for 3 months, tailored to your dietary needs, preferences, and health goals.</li> Our nutrition expert will provide you with a comprehensive plan that includes meal ideas, portion control, and healthy eating tips.
                      </div>
                        <div class="btn-box">
                            <a href="" class="theme-btn btn-style-one">Value: Rs 2,000</a>
                        </div>
                    </div>
                </div>

                
                <div class="image-column col-lg-6 col-md-12 col-sm-12">
                    <div class="inner-column wow fadeInLeft">
                      <div class="author-desc">
                        <h2>Total Value: Rs 11,000</h2>
                        
                      </div>
                        <figure class="image-1"><a href="#" class="lightbox-image" data-fancybox="images"><img title="24befit" src={Honeprice} alt=""></img></a></figure>
                     
                    </div>
                </div>
              
            </div>
           <div class="sec-title">
                             <h2>Meditation Sessions</h2>
                        </div>
          <div class="text">
          <li>3 sessions of guided meditation (1 session per month) to help you relax, reduce stress, and improve your mental clarity.</li> Our experienced meditation instructors will guide you through a series of techniques to calm your mind and promote inner peace.
              </div>
              <div class="btn-box">
                            <a href="" class="theme-btn btn-style-one">Value: Rs 1,000</a>
                        </div>
              <div>
              <h2>Healthy Food Recipes</h2>

              <li>A collection of healthy food recipes (20 recipes) that are easy to prepare, delicious, and tailored to your dietary needs.</li> Our nutrition expert will provide you with a comprehensive recipe book that includes breakfast, lunch, dinner, and snack ideas.
              <div class="btn-box">
                            <a href="" class="theme-btn btn-style-one">Value: Rs 2,000</a>
                        </div>

                        <section className="body-left">
                        <h1><b>Package Validity

3 months from the date of purchase</b></h1>
                        <p><h2>Cancellation Policy</h2>

<li>- 50% refund if cancelled within 3 days of purchase</li>
<li>- 25% refund if cancelled within 1 week of purchase</li>
<li>- No refund if cancelled after 1 week of purchase</li></p>

                        <p><h3>Terms and Conditions</h3>

<li>- The package is non-transferable and cannot be shared with others.</li>
<li>- The package is valid for 3 months from the date of purchase.</li>
<li>- The healthy food recipes will be provided in a digital format (PDF) and can be accessed online.</li>
<li>- Cancellation policy applies as stated above.</li>
<li>- Our instructors reserve the right to modify or cancel sessions due to unforeseen circumstances.</li></p>
                    </section>
                    <div class="btn-box">
                            <a href="" class="theme-btn btn-style-one">Total :Rs 6000 +Rs 2000 + Rs 1000 + Rs 2,000 = Rs 11,000</a>
                        </div>

                    <div className='header-cta'>
                        <Button text={"Buy@Rs11,000 Only"} btnClass={"btn-orange"} href="https://rzp.io/i/TazcUbLi" />
                        <Button text={"Home"} btnClass={"btn-orange"} href="https://fitbeautie.com/" />
                        <Button text={"Top pg"} btnClass={"btn-orange"} href="#top" />
                    </div>
                </div>
                </div>
                </section>
            <br />
            <Footer />
        </>
    )
}

export default Hpwone ;