import './App.css';
import { Routes ,Route } from 'react-router-dom';
import Home from './Home';
import Foodplan from './components/FoodPlan/Foodplan';
import Footer from './components/footer/Footer';
import Navbar from './components/navbar/Navbar';
import Body from './Pages/Body/Body';
import Slide from './Slide/Slide';
import FitnessPlan from './components/FitnessPlan/FitnessPlan';
import Face from './Pages/Face/Face';
import Hair from './Pages/Hair/Hair';
import Skinage from './Skin/Skinage';
import Career from './Pages/Career/Career';
import Contactus from './Pages/Contactus/Contactus';
import Angermanagement from './Bolgs/Angermanagement';
import Breakup from './Bolgs/Breakup';
import Phoneaddict from './Bolgs/Phoneaddict';
import Strongmind from './Bolgs/Strongmind';
import Choosetheright from './Bolgs/Choosetheright';
import Lifestyle from './Bolgs/Lifestyle';
import Meter from './Pages/Weightmeter/Meter';
import Nutriadver from './Pages/Nutriadvertis/Nutriadver';
import News from './Pages/News/News';
import Popup from './Popup/Popup';
import Price from './BestPrice/Price';
import Active from './Active/Active';
import Bookmyservices from './Pages/Bookmyservices/Bookmyservices';
import Team from './components/Team/Team';
import Signup from './Pages/Signup/Signup';
import Sleep from './Pages/Sleep/Sleep';
import Exercise from './Pages/Exercise/Exercise';
import Dietplan from './Pages/Dietplan/Dietplan';
import Sleepblog from './Pages/SleepBlog/Sleepblog';
import Exerciseblog from './Pages/Exerciseblog/Exerciseblog';
import Dietblog from './Pages/Dietblog/Dietblog';
import Aboutus from './Pages/Aboutus/Aboutus';
import Hpwone from './Pages/Hpwone/Hpwone';
import Hpwtwo from './Pages/Hpwtwo/Hpwtwo';
import Pservice from './Pages/Pservices/Pservice';


function App() {
  return (
    <>
    <Routes>
           <Route exact  path='/' element={<Home/>} />
           <Route exact  path='/foodplan' element={<Foodplan/>} />
           <Route exact  path='/footer' element={<Footer/>} />
           <Route exact  path='/navbar' element={<Navbar/>} />
           <Route exact  path='/body' element={<Body/>} />
           <Route exact  path='/slide' element={<Slide/>} />
           <Route exact  path='/fitnessplan' element={<FitnessPlan/>} />
           <Route exact  path='/face' element={<Face/>} />
           <Route exact  path='/hair' element={<Hair/>} />
           <Route exact  path='/skinage' element={<Skinage/>} />
           <Route exact  path='/career' element={<Career/>} />
           <Route exact  path='/contactus' element={<Contactus/>} />
           <Route exact path='/angermanagement' element={<Angermanagement/>}/>
           <Route exact path='/yoga' element={<Breakup/>}/>
           <Route exact path='/phoneaddict' element={<Phoneaddict/>}/>
           <Route exact path='/strongmind' element={<Strongmind/>}/>
           <Route exact path='/theright' element={<Choosetheright/>}/>           
           <Route exact path='/lifestyle' element={<Lifestyle/>}/>
           <Route exact path='/meter' element={<Meter/>}/>
           <Route exact path='/nutriservice' element={<Nutriadver/>}/>
           <Route exact path='/blogs' element={<News/>}/>
           <Route exact path='/popup' element={<Popup/>}/>
           <Route exact path='/bestprice' element={<Price/>}/>
           <Route exact path='/services' element={<Active/>}/>
           <Route exact path='/bookmyservices' element={<Bookmyservices/>}/>
           <Route exact path='/team' element={<Team/>}/>
           <Route exact path='/signup' element={<Signup/>}/>
           <Route exact path='/sleep' element={<Sleep/>}/>
           <Route exact path='/exercise' element={<Exercise/>}/>
           <Route exact path='/dietplan' element={<Dietplan/>}/>
           <Route exact path='/sleepblog' element={<Sleepblog/>}/>
           <Route exact path='/exerciseblog' element={<Exerciseblog/>}/>
           <Route exact path='/dietblog' element={<Dietblog/>}/>
           <Route exact path='/aboutus' element={<Aboutus/>}/>
           <Route exact path='/hpwone' element={<Hpwone/>}/>
           <Route exact path='/hpwtwo' element={<Hpwtwo/>}/>
           <Route exact path='/pservice' element={<Pservice/>}/>
           </Routes>
     </>
  );
}

export default App;
