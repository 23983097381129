import React from 'react'
import "./Dietplan.css";
import Button from '../../components/UI/button/Button';
import "../../components/UI/button/Button.css";


const Dietplan = () => {
  return (
    <section id ="dpservice">
    <div className="container heroservice mt-5 pt-5 h-25">
   <h1 className="display-1 text-white text-shadow animate-text-right-3s">
   <ul>Personalized Diet Planning</ul>
   </h1>
   <h3>Our personalized diet planning service is designed to provide you with a tailored diet plan that meets your specific health and wellness goals. Our team of expert nutritionists will work closely with you to understand your dietary needs and preferences, 
    and create a personalized diet plan that suits your lifestyle.</h3>
    <h2>Service Timeline:</h2>
<h3>
<ul>1. Initial consultation: 1-2 days</ul>
<ul>2. Dietary assessment: 2-3 days</ul>
<ul>3. Personalized diet plan creation: 3-5 days</ul>
<ul>4. Progress tracking and monitoring: Ongoing for 3 months</ul>
<ul>5. Ongoing support and guidance: Ongoing for 3 months</ul>
</h3>
   <div className='header-cta'>
         <Button text={"Know More"} btnClass={"btn-orange"} href="/dietblog"/>
         <Button text={"Buy@Rs5000 Only"} btnClass={"btn-orange"} href="https://rzp.io/i/REiJvnL"/>
       </div>
 </div>
 </section>
  );
}

export default Dietplan