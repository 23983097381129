import React, { useState } from "react";
import Navbar from '../../components/navbar/Navbar';
import Footer from '../../components/footer/Footer';
import NutriImg from "../../imagess/prebooking.png";


const Bookmyservices = () => {
    const [userData, setUserData] = useState({
        name: "",
        age: "",
        gender: "",
        phone: "",
        email: "",
        message: "",
        coupon: "",
    });

    let name, value;
    const postUserData = (event) => {
        name = event.target.name;
        value = event.target.value;

        setUserData({ ...userData, [name]: value });
    };

    const submitData = async (event) => {
        event.preventDefault();
        const { name, age, gender, phone, email ,message  ,coupon} = userData;

        if (name && age && gender && phone && email && message && coupon) {

            const res = await fetch('https://fbhcontactus-default-rtdb.firebaseio.com/userDataRecords.json', {
                method: "POST",
                header: {
                    "Content-Type": "application/json",
                },

                body: JSON.stringify({
                    name, age, gender,phone, email, message, coupon

                }),

            }
            );
            if (res) {
                setUserData({
                    name: "",
                    age: "",
                    gender: "",
                    phone: "",
                    email: "",
                    message: "",
                    coupon: "",
                });
                alert("Thanks for Booking Services with fitbeautie. Data Submited.")
            }
            else {
                alert("please fill the data properly");
            }
        } else {
            alert("please fill the data properly");
        }
    };


  return (
    <>
    <header className='header-bbg'>
<Navbar />
</header>
<section className="contactus-section" id="fitregistration">
          <div className="container">
              <div className="row">
                  <div className="col-12 col-lg-10 mx-auto">
                      <div className="row">
                          <div className="contact-leftside col-12 col-lg-5">
                              <h1 className="main-heading fw-bold">
                              BOOK MY SERVICE
                              </h1>
                              <p className="main-hero-para">
                              <li>Customized Diet Plan</li> 
                              <li>Live Zoom Exercise Session</li>

                              </p>
                              <p>Simple and Effective</p>
                              <p>
                              <ul>
                             <li> Weight Loss </li>
                                 <li> Weight  Gain </li>
                                 <li> Diabetic Patient </li>
                                     <li> Hypertensive Patient </li>
                                     <li> Energy & Immunity Building </li>
</ul>
</p>
                              <figure>
                                  <img
                                      src={NutriImg}
                                      alt="contactimg"
                                      className="img-fluid"
                                  />
                              </figure>
                          </div>

                          <div className="contact-rightside col-12 col-lg-7 ">
                              <form method="POST">
                                  <div className="row">
                                      <div className="col-12 contact-input-feild">
                                          <input
                                              type="text"
                                              name="name"
                                              id=""
                                              className="form-control"
                                              placeholder="name"
                                              value={userData.name}
                                              onChange={postUserData}
                                          />
                                      </div>
                                  </div>
                                  <div className="row">
                                      <div className="col-12 contact-input-feild">
                                          <input
                                              type="text"
                                              name="age"
                                              id=""
                                              className="form-control"
                                              placeholder="age"
                                              value={userData.age}
                                              onChange={postUserData}
                                          />
                                      </div>
                                  </div>
                                  <div className="row">
                                      <div className="col-12 contact-input-feild">
                                          <input
                                              type="text"
                                              name="gender"
                                              id=""
                                              className="form-control"
                                              placeholder="gender"
                                              value={userData.gender}
                                              onChange={postUserData}
                                          />
                                      </div>
                                  </div>
                                  <div className="row">
                                      <div className="col-12 contact-input-feild">
                                          <input
                                              type="text"
                                              name="phone"
                                              id=""
                                              className="form-control"
                                              placeholder="mobile"
                                              value={userData.phone}
                                              onChange={postUserData}
                                          />
                                      </div>
                                  </div>
                                  <div className="row">
                                      <div className="col-12 contact-input-feild">
                                          <input
                                              type="text"
                                              name="email"
                                              id=""
                                              className="form-control"
                                              placeholder="email"
                                              value={userData.email}
                                              onChange={postUserData}
                                          />
                                      </div>
                                  </div>
                                  <div className="row">
                                      <div className="col-12 ">
                                          <input
                                              type="text"
                                              name="message"
                                              id=""
                                              className="form-control"
                                              placeholder="Booking for Diet / Fitness / Both Diet & Fitness /Plan No. "
                                              value={userData.message}
                                              onChange={postUserData}
                                          />
                                      </div>
                                  </div>
                                  <div className="row">
                                      <div className="col-12 contact-input-feild">
                                          <input
                                              type="text"
                                              name="coupon"
                                              id=""
                                              className="form-control"
                                              placeholder="coupon code or else just type *fb*"
                                              value={userData.coupon}
                                              onChange={postUserData}
                                          />
                                      </div>
                                      </div>
                                  <button type="submit" className="btn btn-style w-100" onClick={submitData}>
                                      submit
                                  </button>
                              </form>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>
<Footer/>
</>
  )
}

export default Bookmyservices