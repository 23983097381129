import React, {useState} from "react";
import { MdOutlineLibraryBooks } from "react-icons/md";
import Footer from '../../components/footer/Footer';
import Navbar from '../../components/navbar/Navbar';
import "./Career.css";
import Csection from './Csection';
import { careers } from "./data";
import CareerImg from "../../imagess/careerjob.png";

const Career = () => {

  const [userData, setUserData] = useState({
    postion:"",
    name: "",
    phone: "",
    email: "",
    dateofbirth:"",
    eduone:"",
    experience:"",
    curentcompany:"",
    address: "",
    message: "",
  });

  let name, value;
  const postUserData = (event) => {
    name = event.target.name;
    value = event.target.value;

    setUserData({ ...userData, [name]: value });
  };

  const submitData = async (event) => {
    event.preventDefault();
    const { postion, name, phone, email, dateofbirth, eduone, experience, curentcompany, address, message } = userData;

    if (postion && name && phone && email && dateofbirth && eduone && experience && curentcompany && address && message) {

      const res = await fetch('https://fbhcontactus-default-rtdb.firebaseio.com/userDataRecords.json', {
        method: "POST",
        header: {
          "Content-Type": "application/json",
        },

        body: JSON.stringify({
         postion, name, phone, email, dateofbirth, eduone, experience, curentcompany, address, message

        }),

      }
      );
      if (res) {
        setUserData({
          postion:"",
          name: "",
          phone: "",
          email: "",
          dateofbirth:"",
          eduone:"",
          experience:"",
          curentcompany:"",
          address: "",
          message: "",
        });
        alert("Thank you! Your submission has been sent.")
      }
      else {
        alert("please fill the data properly");
      }
    } else {
      alert("please fill the data properly");
    }
  };

  return (
    <>
       <div  className='u-title'>
      <h1><strong>It's a Pleasure To Announce We Are Hiring</strong></h1>
      <h2>You can dream about it and we can work together to make it happen.</h2>
      </div>
      <header className='header-bbg'>
        <Navbar />
      </header>
      <br/><br/>

      <section id="career">
      
        <div className='container career'>
          <div className='u-title'>
            <MdOutlineLibraryBooks color="orangered"
              size={30} />
            <h2>Careers At 24BEFIT</h2>
            <p className='u-text-small u-text-dark'>
            We're a pioneering start up that's revolutionizing the beauty, health, fitness, and nutrition industry. Our mission is to provide a holistic approach to wellness, empowering individuals to achieve their goals and live their best lives.
            </p>
            <h2> <b>What sets us apart?</b>

-<b> Flexibility:</b> Enjoy the freedom to work from the comfort of your own home, with no commuting stress or office distractions.
-<b> Predictable schedules:</b> Receive client schedules and appointments in advance, allowing you to prepare and deliver exceptional services.
-<b> Autonomy:</b> Take control of your work-life balance, with the flexibility to choose your own hours and workload.</h2>

<h3>Join our team and thrive</h3>

<h4>As a valued member of our startup, you'll have the opportunity to:

- Work with a diverse range of clients, each with their unique needs and goals.
- Develop your skills and expertise in beauty, health, fitness, and nutrition.
- Be part of a supportive community that encourages collaboration and growth.
- Enjoy a stable and secure income, with opportunities for professional advancement.</h4>
<h3>Ready to embark on this exciting journey?</h3>

<h4>If you're passionate about wellness and committed to delivering exceptional service, we invite you to join our team. Together, let's empower individuals to achieve their full potential and live their best lives.</h4>
          </div>
          <div className='career'>
            {
              careers.map((careers) => (
                <Csection title={careers.title} answer={careers.answer} />
              ))
            }
          </div>
        </div>

      </section>
      <section className="contactus-section" id="contactus">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-10 mx-auto">
              <div className="row">
                <div className="contact-leftside col-12 col-lg-5">
                  <h1 className="main-heading fw-bold">
                    Apply Here - Basic Details
                  </h1>
                  <p className="main-hero-para">
                  Choose the postion according to your skills and fill the details
                  </p>
                  <figure>
                    <img
                      src={CareerImg}
                      alt="contactimg"
                      className="img-fluid"
                    />
                  </figure>
                </div>

                <div className="contact-rightside col-12 col-lg-7 ">
                  <form method="POST">
                    <div className="row">
                      <div className="col-12 contact-input-feild">
                        <input
                          type="text"
                          name="postion"
                          id=""
                          className="form-control"
                          placeholder="Applying Postion Title "
                          value={userData.postion}
                          onChange={postUserData}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 contact-input-feild">
                        <input
                          type="text"
                          name="name"
                          id=""
                          className="form-control"
                          placeholder="Name"
                          value={userData.name}
                          onChange={postUserData}
                        />
                      </div>
                    </div>
                      <div className="row">
                      <div className="col-12 contact-input-feild">
                        <input
                          type="text"
                          name="phone"
                          id=""
                          className="form-control"
                          placeholder="Mobile"
                          value={userData.phone}
                          onChange={postUserData}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 contact-input-feild">
                        <input
                          type="text"
                          name="email"
                          id=""
                          className="form-control"
                          placeholder="Email"
                          value={userData.email}
                          onChange={postUserData}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 contact-input-feild">
                        <input
                          type="text"
                          name="dateofbirth"
                          id=""
                          className="form-control"
                          placeholder="Date of Birth"
                          value={userData.dateofbirth}
                          onChange={postUserData}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 contact-input-feild">
                        <input
                          type="text"
                          name="eduone"
                          id=""
                          className="form-control"
                          placeholder="Education Qualification(Highest)"
                          value={userData.eduone}
                          onChange={postUserData}
                        />
                      </div>
                    </div>
                    
                    <div className="row">
                      <div className="col-12 contact-input-feild">
                        <input
                          type="text"
                          name="experience"
                          id=""
                          className="form-control"
                          placeholder="Experience (Fresher/2 months/6 months/1 year/2 year or more)"
                          value={userData.experience}
                          onChange={postUserData}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 contact-input-feild">
                        <input
                          type="text"
                          name="curentcompany"
                          id=""
                          className="form-control"
                          placeholder="Current Company"
                          value={userData.curentcompany}
                          onChange={postUserData}
                        />
                      </div>
                    </div>
                    
                    
                    <div className="row">
                      <div className="col-12 contact-input-feild">
                        <input
                          type="text"
                          name="address"
                          id=""
                          className="form-control"
                          placeholder="Current City"
                          value={userData.address}
                          onChange={postUserData}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 ">
                        <input
                          type="text"
                          name="message"
                          id=""
                          className="form-control"
                          placeholder="Few Words About You"
                          value={userData.message}
                          onChange={postUserData}
                        />
                      </div>
                    </div>
                    <button type="submit" className="btn btn-style w-100" onClick={submitData}>
                      submit application
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  )
};

export default Career;
