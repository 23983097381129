import React from 'react';
import "./Nutriadver.css";
import Button from '../../components/UI/button/Button';
import "../../components/UI/button/Button.css";


const Nutriadver = () => {
  return (
       <section id ="heroservice">
       <div className="container heroservice mt-5 pt-5 h-25">
      <h1 className="display-1 text-white text-shadow animate-text-right-3s">
      <ul>Follow 24BEFIT On INSTAGRAM</ul>
      </h1>
      <h1>Stay Fit, Stay Happy!</h1>
      <h3>Follow us for a dose of wellness and self-love!

More contents, tips, and videos on:</h3>
<h3>Fitness: workouts, exercises, and yoga routines
Food: healthy recipes, nutrition tips, and meal ideas
Lifestyle: stress management, mindfulness, and productivity hacks
Beauty: skincare routines, hair care tips, and makeup tutorials</h3>
<h2>Subscribe to our page for daily updates and inspiration!

Let's embark on this journey to a healthier, happier us!</h2>
      <div className='header-cta'>
            <Button text={"@24befit ->>>"} btnClass={"btn-orange"} href="https://www.instagram.com/24befit/"/>
          </div>
    </div>
    </section>
  );
};

export default Nutriadver;
