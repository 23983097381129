import { IoBodySharp } from "react-icons/io5";
import {IoFastFoodSharp} from "react-icons/io5";
import {MdOutlineFitnessCenter} from "react-icons/md";

export const featureList = [

    {
        id: 1,
        icon: <MdOutlineFitnessCenter color="#0a1930" size={22}/>,
        link: "/body",
        heading: "Fitness - Yoga/Exercise",
        text: "We offer personal training sessions, group classes, and diet/nutrition counseling. All of our instructors are certified in their fields and have extensive experience working with clients just like you.",



    },
    
    {
        id: 2,
        icon: <IoFastFoodSharp color="#0a1930" size={22}/>,
        link: "/foodplan",
        heading: "Food - Recepies/Diet Plans",
        text: "Our nutritionists will help you develop a healthy eating plan that fits within your budget while still maintaining good nutritional value. They'll also provide guidance on how best to incorporate new foods into your diet without making them feel strange or uncomfortable--we've got all kinds of ideas!",

    },
    
    {
        id: 3,
        icon: <IoBodySharp color="#0a1930" size={22}/>,
        link: "/fitnessplan",
        heading: "Our Other Top All Round Services",
        text: "You can get the best, reasonably priced workout programmes right here.",


    },
    
]