import React , { useState } from "react";
import "./Csection.css";
import { AiOutlinePlus, AiOutlineMinus} from "react-icons/ai";

const Csection = ({title,answer}) => {

    const [showAnswer, setShowAnswer] = useState(false)

    const handleClick = () => {
        setShowAnswer(!showAnswer);
    };
    return (
        <div className="container csection">
        <div className="csection-title">
            <h4>{title}</h4>
            <button className="csection-icon" onClick={handleClick}>
{showAnswer ? (
    <AiOutlineMinus color="red" />
) : (
    <AiOutlinePlus color="#1f93ff"/>
)

}

            </button> 
        </div>
        <div className="csection-answer">
        {showAnswer &&  <p className="u-text-small">
                {answer}
            </p>  }
           
        </div>

        </div>
    )


}

export default Csection