import React from 'react';
import "./Blogs.css";
import Navbar from '../components/navbar/Navbar';
import Footer from '../components/footer/Footer';


import Nutform from "../imagess/ntform.jpg";
import Hwework from "../imagess/fitpllan.jpg";
import Advntimg from "../imagess/fitchall.jpg";
import FaceAdd from "../imagess/faceadd.jpg";


const Breakup = () => {
    return (
        <>
            <header className='header-bbg'>
                <Navbar />
            </header>
            <section className='body-left'>
                <div class="bg-gray-200 min-h-screen">
                    <div className="max-w-screen-xl mx-auto px-4 py-16">
                        <div className="flex flex-col flex-wrap md:flex-row md:-mx-3">
                            <div className="w-full lg:w-3/4 mb-8 lg:mb-0">
                                <div className="p-8 bg-white">
                                    <h1 className=" font-bold text-4xl md:text-5xl pb-8 leading-tight">Fitbeautie Yoga Class</h1>
                                    <div className="bg-gray-200 mb-6 inline-block py-1 px-2 italic text-sm">Posted on: <time datetime="2023-01-01 00:12">Jan 01, 2023</time> by <b><u>fitbeautie Blogger Team</u></b></div>
                                    <div className="leading-relaxed">
                                        <h2 className="font-bold text-3xl pb-6 leading-tight">What types of yoga is best for beginners?</h2>
                                        <p className="pb-6"><strong>1. Hatha Yoga: </strong> This is the most common type of yoga and is great for beginners because it focuses on basic poses, deep breathing, and relaxation.

<strong>2. Vinyasa Yoga: </strong> This type of yoga is a bit more challenging than Hatha and focuses on linking poses with your breath.

<strong>3. Yin Yoga: </strong>This type of yoga is slower-paced and focuses on stretching and deep breathing.

<strong>4. Restorative Yoga:</strong> This type of yoga is great for beginners because it uses props such as blankets and blocks to help the body relax into different poses.

<strong>5. Iyengar Yoga: </strong>This type of yoga is perfect for beginners because it emphasizes alignment and proper technique.</p>
                                        <h2 className="font-bold text-3xl pb-6 leading-tight">What are the benefits of a regular yoga practice?</h2>
                                        <p className="pb-6"><strong>1. Improved Flexibility and Balance:</strong> Regular practice of yoga can help improve flexibility, strength, and balance, allowing you to move more easily and with greater control.

                                        <strong>2. Improved Respiratory and Cardiovascular Health:</strong> Yoga can help you to improve your respiratory and cardiovascular health by improving your stamina and by increasing your heart rate.

                                        <strong>3. Improved Mental Health: </strong>Yoga can help reduce stress and anxiety, improve mood, and increase concentration and focus.

                                        <strong>4. Improved Posture and Mobility: </strong>Regular practice of yoga can help improve posture and mobility, allowing for better alignment and greater ease of movement.

                                        <strong>5. Improved Core Strength:</strong> Core strength is essential for many activities of daily living, and yoga can help to improve core strength.

                                        <strong>6. Improved Body Awareness:</strong> Regular practice of yoga can help to increase body awareness, allowing you to become more in tune with your body and its needs.
                                        </p> 
                                        <h2 className=" font-bold text-3xl pb-6 leading-tight">What are different types of yoga ?</h2>
                                        <p className=" pb-6"><strong>1. Hatha Yoga:</strong> This type of yoga focuses on physical postures and breathing exercises.

                                        <strong>2. Vinyasa Yoga:</strong> Also known as Vinyasa Flow or Flow yoga, this type of yoga is based on a series of poses that flow from one to the next.

                                        <strong>3. Iyengar Yoga:</strong> This type of yoga focuses on precise alignment and the use of props such as blocks and straps.

                                        <strong>4. Ashtanga Yoga:</strong> This type of yoga is a vigorous form of yoga that follows a specific sequence of postures.

                                        <strong>5. Bikram Yoga:</strong> Bikram yoga is a type of hot yoga that includes 26 postures and two breathing exercises.

                                        <strong>6. Kundalini Yoga:</strong> This type of yoga focuses on awakening the dormant energy at the base of the spine.

                                        <strong>7. Restorative Yoga:</strong> This type of yoga is designed to relax the body and mind and is ideal for those looking for a gentle form of yoga.

                                        <strong>8. Yin Yoga:</strong> Yin yoga is a slow-paced style of yoga that focuses on deep stretching and relaxation.
                                        </p>

                                        </div><h2 className="font-bold text-3xl pb-6 leading-tight">What are the most effective breathing techniques for yoga ?</h2>
                                        <p className=" pb-6"><strong>1. Ujjayi Breath:</strong> Also known as “ocean breath,” this technique involves breathing in and out through the nose with the throat and mouth closed. As you inhale, the back of your throat should constrict, producing a sound like the ocean.

                                        <strong>2. Alternate Nostril Breathing: </strong>This technique involves breathing in and out through alternate nostrils, closing off one side and breathing in and out of the other. It helps to equalize the pressure in both sides of the brain and can be calming.

                                        <strong>3. Dirga Pranayama:</strong> This technique is also known as the three-part breath. It involves inhaling deeply into the belly, then switching to the rib cage, and finally up to the chest. Each part of the breath should be equal in duration and depth.

                                        <strong>4. Sama Vritti:</strong> This breathing technique is also known as “equal breathing,” and it involves inhaling and exhaling for the same amount of time. For example, you can inhale for a count of four and exhale for a count of four. This breathing helps to promote relaxation and focus.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
     <Footer />
        </>
    );
};

export default Breakup;
