import React, { useState } from 'react';
import './Navbar.css';
import { AiOutlineBars } from 'react-icons/ai';
import { RiCloseLine } from 'react-icons/ri';
import { TiSocialGooglePlus } from 'react-icons/ti';


const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false)

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  }
  return (
    <>
    <div className='menutop'>
    <a href='/contactus'><h3><b>Contact Us</b></h3></a>{'\u00A0'}{'\u00A0'}
    <a href='/career'><h3><b>Careers</b></h3></a>
      
    </div>  
    <nav className='container navbar'>
      <div className='logo'>
        <p className='logo-text'>
         24BEFIT
        </p>
        <span>2024-25</span>
      </div>
      
      <menu>
        <ul className='nav-links' id={showMenu ? "nav-links-mobile" : "nav-links-mobile-hide"}>
          <li><a href='/'>Home</a></li>
        </ul>
      </menu>
      <div className='menu-icons' onClick={toggleMenu}>
        {
          showMenu ? <RiCloseLine color='#fff' size={30} /> : <AiOutlineBars color='#fff' size={27} />
        }
      </div>
    </nav>
    </>
  );
};

export default Navbar;
