import React from 'react';
import "./Hair.css";
import Navbar from '../../components/navbar/Navbar';
import Footer from '../../components/footer/Footer';

import Hairfood from "../../imagess/hairfood.png";
import Greyhair from "../../imagess/greyhair.jpg";
import Hairyoga from "../../imagess/hairyoga.jpg";

import Cooil from "../../imagess/cooil.jpg";
import Dandruff from "../../imagess/dandruff.jpg";
import Hairloss from "../../imagess/hairloss.jpg";
import Hairsmell from '../../imagess/hairsmell.jpg';
import Shampoo from "../../imagess/shampoo.jpg";
import Washhair from "../../imagess/washhair.jpg";

const Hair = () => {
  return <>
  <header className='header-bbg'>
    <Navbar/>
    </header>

    <div className='u-title '>
            <h2>Hair Issues-Food-Exercises-Yoga</h2>
            <p className='body-left'>Hair care is essential not just for our look but also for our general health. We can look our best and keep our hair and scalp healthy by having healthy hair care routine.</p>
            <p className='body-left'>Feisbodyhair has always chosen to give you solutions in a straightforward manner, which you are going to see  on this page. Simply select your hair problem and click on the solution . You will be guided to a page that will instruct you on how to proceed. Follow to  instructions and put the suggestions into action. Still, if you don't feel you've found the answer, contact us and we'll be happy to help. Only patience is required to see results.</p>
          </div>
          <section className='fourth-container'>
        <div className="row row-cols-1 row-cols-md-3 g-4">
          <div className="col">
            <div className="card h-100">
              <img src={Cooil} className="card-img-top" alt="..." />
              <div className="card-body">
                <h3 className="card-title">What is the best way to get rid of dry hair?</h3>
                                <a href="https://bit.ly/3pUkKw6" target="_blank" className="btn btn-primary">Solution</a>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100">
              <img src={Dandruff} className="card-img-top" alt="..." />
              <div className="card-body">
                <h3 className="card-title">What is the best way to get rid of dandruff?</h3>
                 <a href="https://bit.ly/34C5iNG" target="_blank" className="btn btn-primary">Solution</a>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100">
              <img src={Hairloss} className="card-img-top" alt="..." />
              <div className="card-body">
                <h3 className="card-title">How can one prevent hair loss?</h3>
                      <a href="https://bit.ly/3hX90o3" target="_blank" className="btn btn-primary">Solution</a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='third-container'>
        <div className="row row-cols-1 row-cols-md-3 g-4">
          <div className="col">
            <div className="card h-100">
              <div className="ratio ratio-16x9">
                <iframe src="https://www.youtube.com/embed/E-VpIqKEy7Y" title="YouTube video" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100">
              <div className="ratio ratio-16x9">
                <iframe src="https://www.youtube.com/embed/VFB8DR03GX8" title="YouTube video" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100 ">
              <div className="ratio ratio-16x9">
                <iframe src="https://www.youtube.com/embed/5e8ZZhFOBpc" title="YouTube video" allowfullscreen></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='fourth-container'>
        <div className="row row-cols-1 row-cols-md-3 g-4">
          <div className="col">
            <div className="card h-100">
              <img src={Hairsmell} className="card-img-top" alt="..." />
              <div className="card-body">
                <h3 className="card-title">What can you do to make your hair smell nice?</h3>
                                <a href="https://bit.ly/3vWzoGI" target="_blank" className="btn btn-primary">Solution</a>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100">
              <img src={Shampoo} className="card-img-top" alt="..." />
              <div className="card-body">
                <h3 className="card-title">Do shampoos cause hair to become weak?</h3>
                 <a href="https://bit.ly/3sZYJxF" target="_blank" className="btn btn-primary">Solution</a>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100">
              <img src={Washhair} className="card-img-top" alt="..." />
              <div className="card-body">
                <h3 className="card-title">Is it necessary for me to wash my hair every day?</h3>
                      <a href="https://bit.ly/3MCOAij" target="_blank" className="btn btn-primary">Solution</a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className='body-left'>
         <ol>
         <h1>Follow the steps to keep your hair in good condition . </h1>
<h2>	<li>Oil massage </li></h2>

<p>Once a week, massage your head and wash the oil off after 48 hours. You can go for parachute oil. You can mix parachute oil with castor oil every other week if you want.</p>

<h2>	<li>Wash your hair twice in a week</li>
</h2>
<p>Only used herbal shampoo and conditioner , strictly avoid Lauryl sulphate and Sodium Sulphate.</p>


<h2>	<li>Hot water should never be used to wash your hair.</li>
</h2>

<p>Your hair is greatly influenced by the weather and the water. Washing should always be done with mild-warm or cold water.</p>


<h2>	<li>DRINK PLENTY of WATER</li>
</h2>

<p>Drinking plenty of water keeps you active and encourages hair growth from root to tip. It also prevents split ends and brittle hair structure, as well as promoting a healthier scalp, which means you'll have less problems like dryness, itching, and dandruff.</p>

<h2>	<li>Food /Drinks</li>
</h2>
<p>You can reduce hair fall by drinking fenugreek seeds water that has been soaked overnight or by eating walnuts on an empty stomach every morning. You can also go for  Aawla and Aloevera juice straight in the morning as a good source of Vitamin E.</p>

</ol>
</div>
     <section className='fourth-container'>
        <div className="row row-cols-1 row-cols-md-3 g-4">
          <div className="col">
            <div className="card h-100">
              <img src={Hairfood} className="card-img-top" alt="..." />
              <div className="card-body">
                <h3 className="card-title">Is there a link between your nutrition and the quality of your hair?</h3>
                                <a href="https://bit.ly/3sXWxH1" target="_blank" className="btn btn-primary">Solution</a>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100">
              <img src={Greyhair} className="card-img-top" alt="..." />
              <div className="card-body">
                <h3 className="card-title">Natural Ways To Get Rid Of Grey Hair At Home</h3>
                 <a href="https://bit.ly/3pUdtw0" target="_blank" className="btn btn-primary">Solution</a>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100">
              <img src={Hairyoga} className="card-img-top" alt="..." />
              <div className="card-body">
                <h3 className="card-title">Yoga for hair health</h3>
                      <a href="https://bit.ly/3CxgJTe" target="_blank" className="btn btn-primary">Solution</a>
              </div>
            </div>
          </div>
        </div>
      </section>

       <Footer/>
  </>;
};

export default Hair;
