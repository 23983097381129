import React from 'react';
import "./Blogs.css";
import Navbar from '../components/navbar/Navbar';
import Footer from '../components/footer/Footer';


import Nutform from "../imagess/ntform.jpg";
import Hwework from "../imagess/fitpllan.jpg";
import Advntimg from "../imagess/fitchall.jpg";
import FaceAdd from "../imagess/faceadd.jpg";


const Phoneaddict = () => {
    return (
        <>
            <header className='header-bbg'>
                <Navbar />
            </header>
            <section>
            <div className='body-left'>
                <div className="bg-gray-200 min-h-screen">
                    <div className="max-w-screen-xl mx-auto px-4 py-16">
                        <div className="flex flex-col flex-wrap md:flex-row md:-mx-3">
                            <div className="w-full lg:w-3/4 mb-8 lg:mb-0">
                                <div className="p-8 bg-white">
                                    <h1 className=" font-bold text-4xl md:text-5xl pb-8 leading-tight">Phone Addiction</h1>
                                    <div className="bg-gray-200 mb-6 inline-block py-1 px-2 italic text-sm">Posted on: <time datetime="2022-02-24 13:30">Feb 26, 2022</time> by <b><u>fitbeautie Blogger Team</u></b></div>
                                    <div className="leading-relaxed">
                                        <h2 className="font-bold text-3xl pb-6 leading-tight">Signs you're addicted to your smart devices</h2>
                                        <p className="pb-6">Before going deeper in this topic its very much important to know that are you addicted to your smart device or not . These points will assist you in knowing your addiction. We will also highlight few strong points from the documentary "The Social Dilemma". If you have watched thats awesome if not you can go for this documentary.</p>
                                         
                          
                                        <h2 className="font-bold text-3xl pb-6 leading-tight">Silicon Valley tech gurus are warning about the dangers of social networking, which Big Tech is using to manipulate and influence people.</h2>
                                        <p className="pb-6">Spending too much time on your phone becomes an issue when it consumes so much of your time that you forget about your face-to-face relationships, work, school, hobbies, or other important aspects of your life.If you find yourself ignoring food  to check Facebook updates or checking your phone  constantly while driving or during work , college or lectures, it's time to rethink.</p>
                                        <p>On phone you can react without fear of being criticised. On phone you can use a fake id or mask your identity to express your political, religious, and other views without fear of being judged. The issue arises when you become accustomed to faking in the actual world. You become addicted to your phone as a stress reliever. You become confused between "what is real" and "what is fake".Here, your phone begins to make subconscious decisions about your mind (taste, attitude, and interest) and personality.</p>
                                        <p className="pb-6"><li>Trouble completing tasks on time.</li>
                                        <li>Having a “fear of missing out”.</li>
                                        <li>Isolation from family and friends.</li>
                                        <li>If you forgot your smartphone at home, you may experience fear, anxiety, or panic.</li>
                                        <li>Sleep problems</li>
                                        shows  major issue that you are phone or internet addict. You should get help or take steps to overcome the addiction as soon as possible.
                                        </p>       
                                        <h2 className=" font-bold text-3xl pb-6 leading-tight">You can change how you use your smart gadget to get rid of your phone or internet addiction.</h2>
                                        <p className=" pb-6">
                                        <li>Review your phone use and set limitations.</li>
                                        <li>Change your background to something that inspires you to achieve your goals and get rid of your phone addiction.</li>
<li>You can change your data pack.</li>
<li>Keep your notifications turned off so you aren't continually distracted.</li>
<li>Keep your phone's app list to a minimum. Delete anything that isn't needed or is only for fun.</li>
<li>When sleeping, avoid placing your phone by your side</li>
<li>At certain times of the day, turn off your phone.</li>
<li>Make a schedule when you can use your phone.</li>
<li>Replace healthier activities over mobile phones. Plan to occupy the time with other activities such as meditation, reading, or face-to-face conversations with friends and families.</li>
                                        </p>

                                        <p className=" pb-6">We believe it will be tough at first to follow all of the guidelines, but with time, you will succeed.  Spend quality time with your family, put in a lot of effort, express yourself in public, meet new people, go to new locations, and do other things that make you happy. What makes you happy is happiness.</p>
                                        </div><h2 className="font-bold text-3xl pb-6 leading-tight">Few strong points from the documentary "The Social Dilemma"</h2>
                                <p><li>Every action you take online is being monitored.</li>
                                <li>If you're not paying for the product, then you are the product.</li>
                                <li> Social media was not made with morals; it was made by big tech companies with money on their minds.For them We are “Users.”</li>
                                <li> In the documentary, we are called “lab rats” because, in truth, we are always being studied and tested on.We are an experiment.</li>
                                There's a lot more to learn in the documentary. One strategy to break the cycle is to pick and choose what you want to watch online rather than watching the recommended segment.
                                </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default Phoneaddict;
