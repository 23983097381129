import React, { useState } from "react";
import Footer from "../footer/Footer";
import Navbar from "../navbar/Navbar";
import "./Foodplan.css";
const Foodplan = () => {
    return (
        <>
            <header className='header-bbg'>
                <Navbar />
            </header>
            
            <section className='third-container'>
          <div className="row row-cols-1 row-cols-md-3 g-4">
          <div className="col">
            <div className="card h-100">
              <div className="ratio ratio-16x9">
                <iframe src="https://www.youtube.com/embed/lt7VvRULHP8" title="YouTube video" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100">
              <div className="ratio ratio-16x9">
                <iframe src="https://www.youtube.com/embed/nuRPVJguA2U" title="YouTube video" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100 ">
              <div className="ratio ratio-16x9">
                <iframe src="https://www.youtube.com/embed/AoDFNywLqLI" title="YouTube video" allowfullscreen></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
            <section className='body-left'>
            <div  id="container">
                <h1>It's not just about vitamins and minerals</h1>
                <p><h5>When it comes to nutrition, it's not just about vitamins and minerals; there's a lot more to consider. Rather of discussing tales or blogs, we'll concentrate on the services.We consider your age, income, location, dislikes, religious , health history, and, most significantly, your work profession while designing your nutrition chart. After we've checked all of these boxes, then only we create your food chart.</h5></p>

                <h1 id="howework">How we work ?</h1>
                <p> <b>Step 1 :</b> Call or leave a message requesting that we prepare your nutrition chart. One of our representatives will call you to go through all of the information and pricing. Once you confirm and pay the amount , we'll proceed.<br/>
                <b>Step 2 :</b> You will receive a call within the following 72 hours, and your nutrition chart will be prepared based on your phone conversation and emailed or WhatsApp on the contact details you gave during registration in pdf format.<br/>
                <b>Step 3 :</b> First, we provide you a week nutrition plan and then a month nutrition chart to keep track of your progress.<br/>
                <b> Step 4 :</b> After a month, you pay again and obtain a fresh nutrition chart based on your body's requirements, which you can use to prepare your nutritious meals.</p>

                <h2 id="benefits">Now, before I move on to something else, let me tell you about the advantages of our diet plans.</h2>

                <p><h3>Everyone's body, believe it or not, is completely different from one another. Some people eat everything in the world and never gain a pound, while others are like "hawa bhi khale to mote ho jate hain," which means their bodies transform food into fat quickly even when they consume a low calorie diet.<br/><br/>So come join us, tell us about your challenges and your tale, and we'll design your diet with really simple Indian homemade recipes so you won't have to starve or be afraid after any meal because you're on your way to achieving your dream physique.<br/></h3><br/>
                <b>1.</b> The first advantage is that you will be free of concerns such as what to cook today, whether or not I am getting enough nutrition, and so on.<br/>
                <b>2.</b> If you strictly adhere to the diet chart, almost all of your body's nutritional needs will be met.<br/>
                <b>3.</b> This will assist you in maintaining a high level of energy, as well as good muscle growth.<br/>
                <b>4.</b> You will never be forced to eat a dish you truly hate.<br/>
                <b>5.</b> Your grocery list will be within your budget, saving you from eating unhealthy foods and wasting money.<br/>
                <b>6.</b>You will be aware of which foods to avoid if you have a specific illness. There will be additional advantages as well, which you will discover along the way. (Keep in mind that you should always get your food from a reputable source to ensure quality)</p>

                <p><h2 id="ffoohack">Finally, we'd like to offer you some advice to help you along your eating journey.</h2>
                <b>Food hack 1:</b> If you come from an average home, the following meal pattern is recommended: first breakfast, then mid-morning meal, lunch, evening tea, dinner, and finally a glass of milk before bed.<br/>
                <b>Food hack 2:</b> If you are between the ages of 20 and 39, your energy intake food should be at its maximum, which implies foods that supply more energy. If you're between the ages of 50 and 59, your energy intake will be slightly lower than the age group 20 to 39, which is 90 percent, so you won't need as much energy-giving food as the above group.<br/>
                <b>Food hack 3:</b> If you're a college student who cooks their own food and doesn't have time to prepare all of the ingredients separately, such as rice, dal, and vegetables, you may opt for alternative like vegetable pulao and curd, which will satisfy your body's nutritional needs for the day.<br/>
                <b>Food hack 4:</b> During lactation well-balanced breakfast is essential since it provides you with both energy and calcium, as well as vitamins A and C, so choose a blend of cereals such as wheat and rice. jowar millet bajra.<br/>
                <b>Food hack 5:</b> Hotel foods are unhealthy since they are heated multiple times, causing the food to lose its nutritional value and, instead of aiding you, slowly harming your body.<br/>
                <b>Food hack 6:</b> Okay, we Indians have a poor habit of repeating our eating routines. The important thing to remember is that a consistent eating pattern causes unbalanced nutrients in your body, which can lead to health problems and a weak physique.
</p>

                <p>There will be more tips in the future, so stay tuned to the page to reap the full benefits.Do bookmark this page and take advantage of what it has to offer.Remember to schedule your appointment for the nutrition chart. Look forward to hearing back.</p>
            </div>
            </section>
            <Footer />
        </>
    );
};

export default Foodplan;
